// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import Numeral from "numeral";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function make(currentLocale, zeroFormat, nullFormat, defaultFormat, scalePercentBy100) {
  return {
          currentLocale: currentLocale,
          zeroFormat: zeroFormat,
          nullFormat: nullFormat,
          defaultFormat: defaultFormat,
          scalePercentBy100: scalePercentBy100
        };
}

var Options = {
  make: make
};

function make$1(thousands, decimal) {
  return {
          thousands: thousands,
          decimal: decimal
        };
}

var Delimiters = {
  make: make$1
};

function make$2(thousand, million, billion, trillion) {
  return {
          thousand: thousand,
          million: million,
          billion: billion,
          trillion: trillion
        };
}

var Abbreviations = {
  make: make$2
};

function make$3(symbol) {
  return {
          symbol: symbol
        };
}

var Currency = {
  make: make$3
};

function make$4(delimiters, abbreviations, ordinal, currency) {
  return {
          delimiters: delimiters,
          abbreviations: abbreviations,
          ordinal: ordinal,
          currency: currency
        };
}

var Locale = {
  make: make$4
};

function make$5(format, unformat) {
  return {
          format: format,
          unformat: unformat
        };
}

var RegExps = {
  make: make$5
};

function make$6(regexps, formatFn, unformatFn) {
  return {
          regexps: regexps,
          format: formatFn,
          unformat: unformatFn
        };
}

var Format = {
  make: make$6
};

function registerLocale(key, value) {
  return Numeral.register("locale", key, value);
}

function registerFormat(key, value) {
  return Numeral.register("format", key, value);
}

function locale(key) {
  return Numeral.locale(key);
}

function localeData(key) {
  return Numeral.localeData(key);
}

function setZeroFormat(str) {
  Numeral.zeroFormat(str);
}

function setDefaultFormat(str) {
  Numeral.defaultFormat(str);
}

function validate(a, b) {
  return Numeral.validate(a, b);
}

function unformat(t, value) {
  return t.unformat(value);
}

function value(t) {
  return t.value();
}

function difference(t, value) {
  return t.difference(value);
}

function registerLocale$1(key, value) {
  return Numeral.register("locale", key, value);
}

function registerFormat$1(key, value) {
  return Numeral.register("format", key, value);
}

function locale$1(key) {
  return Numeral.locale(key);
}

function localeData$1(key) {
  return Numeral.localeData(key);
}

function setZeroFormat$1(str) {
  Numeral.zeroFormat(str);
}

function setDefaultFormat$1(str) {
  Numeral.defaultFormat(str);
}

function validate$1(a, b) {
  return Numeral.validate(a, b);
}

function unformat$1(t, value) {
  return Caml_option.nullable_to_opt(t.unformat(value));
}

function value$1(t) {
  return Caml_option.nullable_to_opt(t.value());
}

function difference$1(t, value) {
  return Caml_option.nullable_to_opt(t.difference(value));
}

var $$String = {
  registerLocale: registerLocale$1,
  registerFormat: registerFormat$1,
  locale: locale$1,
  localeData: localeData$1,
  setZeroFormat: setZeroFormat$1,
  setDefaultFormat: setDefaultFormat$1,
  validate: validate$1,
  unformat: unformat$1,
  value: value$1,
  difference: difference$1
};

function numberToFormat(value, format, roundingFunction) {
  return Curry._3(Numeral._.numberToFormat, value, format, roundingFunction);
}

function stringToNumber(value) {
  return Curry._1(Numeral._.stringToNumber, value);
}

function includes(string, search) {
  return Curry._2(Numeral._.includes, string, search);
}

function insert(string, subString, start) {
  return Curry._3(Numeral._.insert, string, subString, start);
}

function multiplier(x) {
  return Curry._1(Numeral._.multiplier, x);
}

function correctionFactor1(arg1) {
  return Curry._1(Numeral._.correctionFactor, arg1);
}

function correctionFactor2(arg1, arg2) {
  return Curry._2(Numeral._.correctionFactor, arg1, arg2);
}

function correctionFactor3(arg1, arg2, arg3) {
  return Curry._3(Numeral._.correctionFactor, arg1, arg2, arg3);
}

function correctionFactor4(arg1, arg2, arg3, arg4) {
  return Curry._4(Numeral._.correctionFactor, arg1, arg2, arg3, arg4);
}

function correctionFactor5(arg1, arg2, arg3, arg4, arg5) {
  return Curry._5(Numeral._.correctionFactor, arg1, arg2, arg3, arg4, arg5);
}

function toFixed(value, maxDecimals, roundingFunction, optionals) {
  return Curry._4(Numeral._.toFixed, value, maxDecimals, roundingFunction, optionals);
}

var Helpers = {
  numberToFormat: numberToFormat,
  stringToNumber: stringToNumber,
  includes: includes,
  insert: insert,
  multiplier: multiplier,
  correctionFactor1: correctionFactor1,
  correctionFactor2: correctionFactor2,
  correctionFactor3: correctionFactor3,
  correctionFactor4: correctionFactor4,
  correctionFactor5: correctionFactor5,
  toFixed: toFixed
};

if (Numeral.default === undefined) {
  Numeral.default = Numeral;
}
;

export {
  Options ,
  Delimiters ,
  Abbreviations ,
  Currency ,
  Locale ,
  RegExps ,
  Format ,
  registerLocale ,
  registerFormat ,
  locale ,
  localeData ,
  setZeroFormat ,
  setDefaultFormat ,
  validate ,
  unformat ,
  value ,
  difference ,
  $$String ,
  Helpers ,
}
/*  Not a pure module */
