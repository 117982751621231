// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Data_Id$Coronate from "./Data/Data_Id.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function fromPath(x) {
  if (!x) {
    return /* Players */0;
  }
  switch (x.hd) {
    case "" :
        if (x.tl) {
          return ;
        } else {
          return /* Players */0;
        }
    case "crosstable" :
        if (x.tl) {
          return ;
        } else {
          return /* Crosstable */2;
        }
    case "round" :
        var match = x.tl;
        if (!match) {
          return ;
        }
        if (match.tl) {
          return ;
        }
        var y = Belt_Int.fromString(match.hd);
        if (y !== undefined) {
          return /* Round */{
                  _0: y
                };
        } else {
          return ;
        }
    case "scores" :
        if (x.tl) {
          return ;
        } else {
          return /* Scores */1;
        }
    case "setup" :
        if (x.tl) {
          return ;
        } else {
          return /* Setup */3;
        }
    case "status" :
        if (x.tl) {
          return ;
        } else {
          return /* Status */4;
        }
    default:
      return ;
  }
}

function toString(id, subPage) {
  if (typeof subPage !== "number") {
    return Data_Id$Coronate.toString(id) + "/round/" + String(subPage._0);
  }
  switch (subPage) {
    case /* Players */0 :
        return Data_Id$Coronate.toString(id) + "/";
    case /* Scores */1 :
        return Data_Id$Coronate.toString(id) + "/scores";
    case /* Crosstable */2 :
        return Data_Id$Coronate.toString(id) + "/crosstable";
    case /* Setup */3 :
        return Data_Id$Coronate.toString(id) + "/setup";
    case /* Status */4 :
        return Data_Id$Coronate.toString(id) + "/status";
    
  }
}

function toString$1(x) {
  if (typeof x !== "number") {
    if (x.TAG === /* Tournament */0) {
      return "/tourneys/" + toString(x._0, x._1);
    } else {
      return "/players/" + Data_Id$Coronate.toString(x._0);
    }
  }
  switch (x) {
    case /* TournamentList */1 :
        return "/tourneys";
    case /* PlayerList */2 :
        return "/players";
    case /* TimeCalculator */3 :
        return "/timecalc";
    case /* Options */4 :
        return "/options";
    case /* Index */0 :
    case /* NotFound */5 :
        return "/";
    
  }
}

function useUrl(param) {
  var match = RescriptReactRouter.useUrl(undefined, undefined);
  var x = match.path;
  if (!x) {
    return /* Index */0;
  }
  switch (x.hd) {
    case "options" :
        if (x.tl) {
          return /* NotFound */5;
        } else {
          return /* Options */4;
        }
    case "players" :
        var match$1 = x.tl;
        if (match$1) {
          if (match$1.tl) {
            return /* NotFound */5;
          } else {
            return {
                    TAG: /* Player */1,
                    _0: Data_Id$Coronate.fromString(match$1.hd)
                  };
          }
        } else {
          return /* PlayerList */2;
        }
    case "timecalc" :
        if (x.tl) {
          return /* NotFound */5;
        } else {
          return /* TimeCalculator */3;
        }
    case "tourneys" :
        var match$2 = x.tl;
        if (!match$2) {
          return /* TournamentList */1;
        }
        var page = fromPath(match$2.tl);
        if (page !== undefined) {
          return {
                  TAG: /* Tournament */0,
                  _0: Data_Id$Coronate.fromString(match$2.hd),
                  _1: page
                };
        } else {
          return /* NotFound */5;
        }
    default:
      return /* NotFound */5;
  }
}

function Router$Link(Props) {
  var children = Props.children;
  var to_ = Props.to_;
  var onDragStart = Props.onDragStart;
  var onClick = Props.onClick;
  var path = useUrl(undefined);
  var href = toString$1(to_);
  var tmp = {
    href: href,
    onClick: (function ($$event) {
        if (onClick !== undefined) {
          Curry._1(onClick, $$event);
        }
        if (!$$event.defaultPrevented) {
          $$event.preventDefault();
          return RescriptReactRouter.push(href);
        }
        
      })
  };
  if (onDragStart !== undefined) {
    tmp.onDragStart = Caml_option.valFromOption(onDragStart);
  }
  return React.cloneElement(React.createElement("a", tmp, children), {
              "aria-current": href === toString$1(path)
            });
}

var Link = {
  make: Router$Link
};

var TourneyPage = {};

export {
  TourneyPage ,
  useUrl ,
  Link ,
}
/* react Not a pure module */
