// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Match$Blossom from "rescript-blossom/src/Match.bs.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Data_Id$Coronate from "./Data_Id.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Data_Scoring$Coronate from "./Data_Scoring.bs.js";

function id(param) {
  return param.id;
}

function players(param) {
  return param.players;
}

function maxPriority(param) {
  return param.maxPriority;
}

function descendingRating(param, param$1) {
  return Utils$Coronate.descend(Caml.int_compare, (function (x) {
                return x.rating;
              }), param, param$1);
}

function splitInHalf(arr) {
  var midpoint;
  try {
    midpoint = (arr.length >> 1);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Division_by_zero") {
      midpoint = 0;
    } else {
      throw exn;
    }
  }
  return [
          Belt_Array.slice(arr, 0, midpoint),
          Belt_Array.sliceToEnd(arr, midpoint)
        ];
}

function setUpperHalves(data) {
  var dataArr = Belt_Map.valuesToArray(data);
  return Belt_Map.map(data, (function (playerData) {
                var match = splitInHalf(Belt_SortArray.stableSortBy(Belt_Array.keep(dataArr, (function (param) {
                                return param.score === playerData.score;
                              })), descendingRating));
                var getIndex = function (__x) {
                  return Belt_Array.getIndexBy(__x, (function (x) {
                                return Data_Id$Coronate.eq(x.id, playerData.id);
                              }));
                };
                var match$1 = getIndex(match[0]);
                var match$2 = getIndex(match[1]);
                var match$3 = match$1 !== undefined ? [
                    match$1,
                    true
                  ] : (
                    match$2 !== undefined ? [
                        match$2,
                        false
                      ] : [
                        0,
                        false
                      ]
                  );
                return {
                        id: playerData.id,
                        avoidIds: playerData.avoidIds,
                        colorScore: playerData.colorScore,
                        lastColor: playerData.lastColor,
                        halfPos: match$3[0],
                        isUpperHalf: match$3[1],
                        opponents: playerData.opponents,
                        rating: playerData.rating,
                        score: playerData.score
                      };
              }));
}

function priority(diffDueColor, isDiffHalf, halfPosDiff, scoreDiff, canMeet, maxScore) {
  var colors = diffDueColor ? 2 : 0;
  var halves = isDiffHalf ? 4 / (halfPosDiff + 1) : 0;
  var scores = maxScore * 16 - scoreDiff * 16;
  var canMeet$1 = canMeet ? 32 * maxScore : 0;
  return colors + halves + scores + canMeet$1;
}

function calcMaxPriority(param) {
  return priority(true, true, 0, 0, true, param);
}

function calcMaxScore(m) {
  return Belt_Map.reduce(m, 0, (function (acc, param, p) {
                if (acc > p.score) {
                  return acc;
                } else {
                  return p.score;
                }
              }));
}

function make(scoreData, playerData, avoidPairs) {
  var avoidMap = Curry._1(Data_Id$Coronate.Pair.$$Set.toMap, avoidPairs);
  var players = setUpperHalves(Belt_Map.mapWithKey(playerData, (function (key, data) {
              var x = Belt_Map.get(scoreData, key);
              var playerStats = x !== undefined ? x : Data_Scoring$Coronate.make(key);
              var x$1 = Belt_Map.get(avoidMap, key);
              var newAvoidIds = x$1 !== undefined ? Caml_option.valFromOption(x$1) : Belt_Set.make(Data_Id$Coronate.id);
              return {
                      id: data.id,
                      avoidIds: newAvoidIds,
                      colorScore: Curry._1(Data_Scoring$Coronate.Score.Sum.toFloat, Data_Scoring$Coronate.Score.sum(playerStats.colorScores)),
                      lastColor: playerStats.lastColor,
                      halfPos: 0,
                      isUpperHalf: false,
                      opponents: Belt_List.map(playerStats.opponentResults, (function (param) {
                              return param[0];
                            })),
                      rating: data.rating,
                      score: Curry._1(Data_Scoring$Coronate.Score.Sum.toFloat, Data_Scoring$Coronate.Score.calcScore(playerStats.results, playerStats.adjustment))
                    };
            })));
  var maxScore = calcMaxScore(players);
  return {
          players: players,
          maxScore: maxScore,
          maxPriority: calcMaxPriority(maxScore)
        };
}

function keep(param, f) {
  var players = Belt_Map.keep(param.players, f);
  var maxScore = calcMaxScore(players);
  return {
          players: players,
          maxScore: maxScore,
          maxPriority: calcMaxPriority(maxScore)
        };
}

function calcPairIdeal(player1, player2, maxScore) {
  if (Data_Id$Coronate.eq(player1.id, player2.id)) {
    return 0.0;
  }
  var partial_arg = player2.id;
  var metBefore = Belt_List.some(player1.opponents, (function (param) {
          return Data_Id$Coronate.eq(partial_arg, param);
        }));
  var mustAvoid = Belt_Set.has(player1.avoidIds, player2.id);
  var canMeet = !metBefore && !mustAvoid;
  var match = player1.lastColor;
  var match$1 = player2.lastColor;
  var diffDueColor = match !== undefined && match$1 !== undefined ? match !== match$1 : true;
  var scoreDiff = Math.abs(player1.score - player2.score);
  var halfPosDiff = Pervasives.abs(player1.halfPos - player2.halfPos | 0);
  var isDiffHalf = player1.isUpperHalf !== player2.isUpperHalf && player1.score === player2.score;
  return priority(diffDueColor, isDiffHalf, halfPosDiff, scoreDiff, canMeet, maxScore);
}

function calcPairIdealByIds(param, p1, p2) {
  var players = param.players;
  var match = Belt_Map.get(players, p1);
  var match$1 = Belt_Map.get(players, p2);
  if (match !== undefined && match$1 !== undefined) {
    return calcPairIdeal(match, match$1, param.maxScore);
  }
  
}

function sortByScoreThenRating(data1, data2) {
  var x = Caml.float_compare(data1.score, data2.score);
  if (x !== 0) {
    return x;
  } else {
    return Caml.int_compare(data1.rating, data2.rating);
  }
}

function setByePlayer(byeQueue, dummyId, data) {
  var hasNotHadBye = function (p) {
    return !Belt_List.some(p.opponents, (function (param) {
                  return Data_Id$Coronate.eq(dummyId, param);
                }));
  };
  var val;
  try {
    val = Belt_Map.size(data.players) % 2;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Division_by_zero") {
      return [
              data,
              undefined
            ];
    }
    throw exn;
  }
  if (val === 0) {
    return [
            data,
            undefined
          ];
  }
  var dataArr = Belt_SortArray.stableSortBy(Belt_Array.keep(Belt_Map.valuesToArray(data.players), hasNotHadBye), sortByScoreThenRating);
  var playerIdsWithoutByes = Belt_Array.map(dataArr, (function (p) {
          return p.id;
        }));
  var hasntHadByeFn = function (id) {
    return Belt_Array.some(playerIdsWithoutByes, (function (param) {
                  return Data_Id$Coronate.eq(id, param);
                }));
  };
  var nextByeSignups = Belt_Array.keep(byeQueue, hasntHadByeFn);
  var id = Belt_Array.get(nextByeSignups, 0);
  var dataForNextBye;
  if (id !== undefined) {
    var x = Belt_Map.get(data.players, Caml_option.valFromOption(id));
    dataForNextBye = x !== undefined ? x : Belt_Array.get(dataArr, 0);
  } else {
    var x$1 = Belt_Array.get(dataArr, 0);
    dataForNextBye = x$1 !== undefined ? x$1 : Belt_Array.get(Belt_SortArray.stableSortBy(Belt_Map.valuesToArray(data.players), sortByScoreThenRating), 0);
  }
  var players = dataForNextBye !== undefined ? Belt_Map.remove(data.players, dataForNextBye.id) : data.players;
  return [
          {
            players: players,
            maxScore: data.maxScore,
            maxPriority: data.maxPriority
          },
          dataForNextBye
        ];
}

function assignColorsForPair(param) {
  var player2 = param[1];
  var player1 = param[0];
  if (player1.colorScore < player2.colorScore) {
    return [
            player2.id,
            player1.id
          ];
  } else {
    return [
            player1.id,
            player2.id
          ];
  }
}

function netScore(param) {
  return param[0].score + param[1].score;
}

function netRating(param) {
  return param[0].rating + param[1].rating | 0;
}

function sortByNetScoreThenRating(pair1, pair2) {
  var x = Caml.float_compare(netScore(pair2), netScore(pair1));
  if (x !== 0) {
    return x;
  } else {
    return Caml.int_compare(netRating(pair2), netRating(pair1));
  }
}

var IdMatch = Match$Blossom.comparable(Data_Id$Coronate.compare);

function pairPlayers(param) {
  var maxScore = param.maxScore;
  var players = param.players;
  return Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.keepMap(Belt_Set.toArray(Match$Blossom.reduce(Match$Blossom.make(undefined, Belt_Map.reduce(players, /* [] */0, (function (acc, p1Id, p1) {
                                        return Belt_Map.reduce(players, acc, (function (acc2, p2Id, p2) {
                                                      return {
                                                              hd: [
                                                                p1Id,
                                                                p2Id,
                                                                calcPairIdeal(p1, p2, maxScore)
                                                              ],
                                                              tl: acc2
                                                            };
                                                    }));
                                      })), IdMatch), Belt_Set.make(Data_Id$Coronate.Pair.id), (function (acc, p1, p2) {
                                var pair = Data_Id$Coronate.Pair.make(p1, p2);
                                if (pair !== undefined) {
                                  return Belt_Set.add(acc, Caml_option.valFromOption(pair));
                                } else {
                                  return acc;
                                }
                              }))), (function (pair) {
                        var match = Data_Id$Coronate.Pair.toTuple(pair);
                        var match$1 = Belt_Map.get(players, match[0]);
                        var match$2 = Belt_Map.get(players, match[1]);
                        if (match$1 !== undefined && match$2 !== undefined) {
                          return [
                                  match$1,
                                  match$2
                                ];
                        }
                        
                      })), sortByNetScoreThenRating), assignColorsForPair);
}

export {
  id ,
  players ,
  maxPriority ,
  make ,
  keep ,
  calcPairIdeal ,
  calcPairIdealByIds ,
  setByePlayer ,
  pairPlayers ,
}
/* IdMatch Not a pure module */
