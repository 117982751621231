// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Data_Id$Coronate from "./Data_Id.bs.js";
import * as Belt_MutableQueue from "rescript/lib/es6/belt_MutableQueue.js";
import * as Data_Match$Coronate from "./Data_Match.bs.js";

function fromArray(x) {
  return x;
}

function toArray(x) {
  return x;
}

var empty = [];

function encode(t) {
  return Belt_Array.map(t, Data_Match$Coronate.encode);
}

function decode(json) {
  return Belt_Array.map(Belt_Option.getExn(Js_json.decodeArray(json)), Data_Match$Coronate.decode);
}

function size(prim) {
  return prim.length;
}

function getMatched(round) {
  var q = Belt_MutableQueue.make(undefined);
  Belt_Array.forEach(round, (function (param) {
          Belt_MutableQueue.add(q, param.whiteId);
          Belt_MutableQueue.add(q, param.blackId);
        }));
  return Belt_MutableQueue.toArray(q);
}

function getMatchById(round, id) {
  return Belt_Array.getBy(round, (function (x) {
                return Data_Id$Coronate.eq(x.id, id);
              }));
}

function removeMatchById(round, id) {
  return Belt_Array.keep(round, (function (x) {
                return !Data_Id$Coronate.eq(x.id, id);
              }));
}

function setMatch(round, match) {
  var round$1 = round.slice(0);
  return Belt_Option.flatMap(Belt_Option.map(Belt_Array.getIndexBy(round$1, (function (param) {
                        return Data_Id$Coronate.eq(param.id, match.id);
                      })), (function (x) {
                    return Belt_Array.set(round$1, x, match);
                  })), (function (wasSuccessful) {
                if (wasSuccessful) {
                  return round$1;
                }
                
              }));
}

function moveMatch(round, matchId, direction) {
  var match_ = getMatchById(round, matchId);
  if (match_ === undefined) {
    return ;
  }
  var oldIndex = round.indexOf(match_);
  var newIndex = (oldIndex + direction | 0) >= 0 ? oldIndex + direction | 0 : 0;
  return Utils$Coronate.$$Array.swap(round, oldIndex, newIndex);
}

var Round = {
  fromArray: fromArray,
  toArray: toArray,
  empty: empty,
  encode: encode,
  decode: decode,
  size: size,
  addMatches: Belt_Array.concat,
  getMatched: getMatched,
  getMatchById: getMatchById,
  removeMatchById: removeMatchById,
  setMatch: setMatch,
  moveMatch: moveMatch
};

function fromArray$1(x) {
  return x;
}

function toArray$1(x) {
  return x;
}

var empty$1 = [[]];

function encode$1(t) {
  return Belt_Array.map(t, encode);
}

function decode$1(json) {
  return Belt_Array.map(Belt_Option.getExn(Js_json.decodeArray(json)), decode);
}

function size$1(prim) {
  return prim.length;
}

function getLastKey(rounds) {
  return rounds.length - 1 | 0;
}

function set(rounds, key, round) {
  var rounds$1 = rounds.slice(0);
  var wasSuccessful = Belt_Array.set(rounds$1, key, round);
  if (wasSuccessful) {
    return rounds$1;
  }
  
}

function setMatch$1(rounds, key, match_) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(rounds, key), (function (__x) {
                    return setMatch(__x, match_);
                  })), (function (param) {
                return set(rounds, key, param);
              }));
}

function rounds2Matches(roundList) {
  var q = Belt_MutableQueue.make(undefined);
  Belt_Array.forEach(roundList, (function (r) {
          Belt_MutableQueue.transfer(Belt_MutableQueue.fromArray(r), q);
        }));
  return q;
}

function isRoundComplete(roundList, players, roundId) {
  var round = Belt_Array.get(roundList, roundId);
  if (round === undefined) {
    return true;
  }
  if (roundId < (roundList.length - 1 | 0)) {
    return true;
  }
  var matched = getMatched(round);
  var unmatched = Belt_Map.removeMany(players, matched);
  var results = Belt_Array.map(round, (function (match) {
          return match.result;
        }));
  if (Belt_Map.size(unmatched) === 0) {
    return !results.includes(/* NotSet */6);
  } else {
    return false;
  }
}

function addRound(roundList) {
  return Belt_Array.concat(roundList, [[]]);
}

function delLastRound(roundList) {
  return roundList.slice(0, -1);
}

function updateByeScores(rounds, byeValue) {
  return Belt_Array.map(rounds, (function (round) {
                return Belt_Array.map(round, (function (m) {
                              return {
                                      id: m.id,
                                      whiteId: m.whiteId,
                                      blackId: m.blackId,
                                      whiteNewRating: m.whiteNewRating,
                                      blackNewRating: m.blackNewRating,
                                      whiteOrigRating: m.whiteOrigRating,
                                      blackOrigRating: m.blackOrigRating,
                                      result: Data_Match$Coronate.Result.scoreByeMatch(m.whiteId, m.blackId, byeValue, m.result)
                                    };
                            }));
              }));
}

var get = Belt_Array.get;

export {
  Round ,
  fromArray$1 as fromArray,
  toArray$1 as toArray,
  empty$1 as empty,
  encode$1 as encode,
  decode$1 as decode,
  size$1 as size,
  getLastKey ,
  get ,
  set ,
  setMatch$1 as setMatch,
  rounds2Matches ,
  isRoundComplete ,
  addRound ,
  delLastRound ,
  updateByeScores ,
}
/* Utils-Coronate Not a pure module */
