// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactFeather from "react-feather";
import * as Hooks$Coronate from "../Hooks.bs.js";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as Belt_MutableQueue from "rescript/lib/es6/belt_MutableQueue.js";
import * as Externals$Coronate from "../Externals/Externals.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as PagePlayers$Coronate from "../PagePlayers.bs.js";
import VisuallyHidden from "@reach/visually-hidden";

var sortFirstName = {
  TAG: /* GetString */0,
  _0: (function (p) {
      return p.firstName;
    })
};

var sortLastName = {
  TAG: /* GetString */0,
  _0: (function (p) {
      return p.lastName;
    })
};

function PageTourneyPlayers$Selecting(Props) {
  var tourney = Props.tourney;
  var setTourney = Props.setTourney;
  var players = Props.players;
  var playersDispatch = Props.playersDispatch;
  var playerIds = tourney.playerIds;
  var match = Hooks$Coronate.useSortedTable(Belt_Map.valuesToArray(players), sortFirstName, false);
  var tableDispatch = match[1];
  var table = match[0];
  var togglePlayer = function ($$event) {
    var id = $$event.target.value;
    if ($$event.target.checked) {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: Belt_Set.add(playerIds, id),
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: tourney.roundList
                });
    } else {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: Belt_Set.keep(playerIds, (function (pId) {
                          return !Data_Id$Coronate.eq(pId, id);
                        })),
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: tourney.roundList
                });
    }
  };
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "toolbar"
                }, React.createElement("button", {
                      className: "button-micro",
                      onClick: (function (param) {
                          Curry._1(setTourney, {
                                id: tourney.id,
                                name: tourney.name,
                                date: tourney.date,
                                playerIds: Belt_Set.fromArray(Belt_Map.keysToArray(players), Data_Id$Coronate.id),
                                scoreAdjustments: tourney.scoreAdjustments,
                                byeQueue: tourney.byeQueue,
                                tieBreaks: tourney.tieBreaks,
                                roundList: tourney.roundList
                              });
                        })
                    }, "Select all"), React.createElement("button", {
                      className: "button-micro",
                      onClick: (function (param) {
                          Curry._1(setTourney, {
                                id: tourney.id,
                                name: tourney.name,
                                date: tourney.date,
                                playerIds: Belt_Set.make(Data_Id$Coronate.id),
                                scoreAdjustments: tourney.scoreAdjustments,
                                byeQueue: tourney.byeQueue,
                                tieBreaks: tourney.tieBreaks,
                                roundList: tourney.roundList
                              });
                        })
                    }, "Select none")), React.createElement("table", undefined, React.createElement("caption", undefined, "Select players"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "First name",
                                  sortColumn: sortFirstName,
                                  data: table,
                                  dispatch: tableDispatch
                                })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "Last name",
                                  sortColumn: sortLastName,
                                  data: table,
                                  dispatch: tableDispatch
                                })), React.createElement("th", undefined, "Select"))), React.createElement("tbody", undefined, Belt_Array.map(table.table, (function (param) {
                            var lastName = param.lastName;
                            var id = param.id;
                            var firstName = param.firstName;
                            return React.createElement("tr", {
                                        key: Data_Id$Coronate.toString(id)
                                      }, React.createElement("td", undefined, firstName), React.createElement("td", undefined, lastName), React.createElement("td", undefined, React.createElement(VisuallyHidden, {
                                                children: React.createElement("label", {
                                                      htmlFor: "select-" + Data_Id$Coronate.toString(id)
                                                    }, "Select " + firstName + " " + lastName + "")
                                              }), React.createElement("input", {
                                                id: "select-" + Data_Id$Coronate.toString(id),
                                                checked: Belt_Set.has(playerIds, id),
                                                type: "checkbox",
                                                value: Data_Id$Coronate.toString(id),
                                                onChange: togglePlayer
                                              })));
                          })))), React.createElement(PagePlayers$Coronate.NewPlayerForm.make, {
                  dispatch: playersDispatch,
                  addPlayerCallback: (function (id) {
                      Curry._1(setTourney, {
                            id: tourney.id,
                            name: tourney.name,
                            date: tourney.date,
                            playerIds: Belt_Set.add(playerIds, id),
                            scoreAdjustments: tourney.scoreAdjustments,
                            byeQueue: tourney.byeQueue,
                            tieBreaks: tourney.tieBreaks,
                            roundList: tourney.roundList
                          });
                    })
                }));
}

var Selecting = {
  make: PageTourneyPlayers$Selecting
};

function hasHadBye(matches, playerId) {
  return Belt_Array.some(Belt_Array.keep(Belt_MutableQueue.toArray(matches), (function (match) {
                    if (Data_Id$Coronate.eq(match.whiteId, playerId)) {
                      return true;
                    } else {
                      return Data_Id$Coronate.eq(match.blackId, playerId);
                    }
                  })), (function (match) {
                if (Data_Id$Coronate.isDummy(match.whiteId)) {
                  return true;
                } else {
                  return Data_Id$Coronate.isDummy(match.blackId);
                }
              }));
}

function errorNotification(x) {
  if (x !== undefined && x.TAG !== /* Ok */0) {
    return React.createElement(Utils$Coronate.$$Notification.make, {
                children: x._0,
                kind: /* Error */2
              });
  } else {
    return null;
  }
}

var initial = {
  scoreAdjustment: /* Pristine */0
};

function scoreAdjustment(scoreAdjustment$1) {
  var x = Belt_Float.fromString(scoreAdjustment$1);
  if (x !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: x
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Score adjustment must be a number."
          };
  }
}

function validateForm(param) {
  var result = param.fieldStatuses.scoreAdjustment;
  var scoreAdjustmentResult = result ? result._0 : scoreAdjustment(param.input.scoreAdjustment);
  if (scoreAdjustmentResult.TAG === /* Ok */0) {
    return {
            TAG: /* Valid */0,
            output: {
              scoreAdjustment: scoreAdjustmentResult._0
            },
            fieldStatuses: {
              scoreAdjustment: /* Dirty */{
                _0: scoreAdjustmentResult
              }
            }
          };
  } else {
    return {
            TAG: /* Invalid */1,
            fieldStatuses: {
              scoreAdjustment: /* Dirty */{
                _0: scoreAdjustmentResult
              }
            }
          };
  }
}

function useForm(initialInput) {
  var memoizedInitialState = React.useMemo((function () {
          return {
                  input: initialInput,
                  fieldStatuses: initial,
                  formStatus: /* Editing */0
                };
        }), [initialInput]);
  var match = React.useReducer((function (state, action) {
          if (typeof action === "number") {
            if (action !== /* BlurScoreAdjustmentField */0) {
              return {
                      input: initialInput,
                      fieldStatuses: initial,
                      formStatus: /* Editing */0
                    };
            }
            var result = Utils$Coronate.FormHelper.validateFieldOnBlurWithValidator(state.input.scoreAdjustment, state.fieldStatuses.scoreAdjustment, scoreAdjustment);
            if (result !== undefined) {
              return {
                      input: state.input,
                      fieldStatuses: {
                        scoreAdjustment: result
                      },
                      formStatus: state.formStatus
                    };
            } else {
              return state;
            }
          } else {
            if (action.TAG === /* UpdateScoreAdjustmentField */0) {
              var nextValue = action._0;
              return {
                      input: {
                        scoreAdjustment: nextValue
                      },
                      fieldStatuses: {
                        scoreAdjustment: /* Dirty */{
                          _0: scoreAdjustment(nextValue)
                        }
                      },
                      formStatus: state.formStatus
                    };
            }
            var match = state.formStatus;
            if (match) {
              return state;
            }
            var match$1 = validateForm(state);
            if (match$1.TAG === /* Valid */0) {
              return {
                      input: state.input,
                      fieldStatuses: match$1.fieldStatuses,
                      formStatus: /* Submitting */{
                        _0: match$1.output,
                        _1: action._0
                      }
                    };
            } else {
              return {
                      input: state.input,
                      fieldStatuses: match$1.fieldStatuses,
                      formStatus: /* Editing */0
                    };
            }
          }
        }), memoizedInitialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var match = state.formStatus;
          if (match) {
            Curry._1(match._1, match._0);
            Curry._1(dispatch, /* Reset */1);
          }
          
        }), [state.formStatus]);
  return {
          state: state,
          dispatch: dispatch
        };
}

function updateScoreAdjustment(param, nextValue) {
  Curry._1(param.dispatch, {
        TAG: /* UpdateScoreAdjustmentField */0,
        _0: nextValue
      });
}

function scoreAdjustmentResult(param) {
  return Utils$Coronate.FormHelper.exposeFieldResult(param.state.fieldStatuses.scoreAdjustment);
}

function input(param) {
  return param.state.input;
}

function valid(param) {
  var match = validateForm(param.state);
  if (match.TAG === /* Valid */0) {
    return true;
  } else {
    return false;
  }
}

function submitting(param) {
  var match = param.state.formStatus;
  if (match) {
    return true;
  } else {
    return false;
  }
}

function PageTourneyPlayers$OptionsForm$More(Props) {
  var setTourney = Props.setTourney;
  var dialog = Props.dialog;
  var tourney = Props.tourney;
  var p = Props.p;
  var scoreAdjustment = Belt_Option.mapWithDefault(Belt_Map.get(tourney.scoreAdjustments, p.id), "0", (function (prim) {
          return String(prim);
        }));
  var form = useForm({
        scoreAdjustment: scoreAdjustment
      });
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: "button-micro button-primary",
                  onClick: (function (param) {
                      Curry._1(dialog.setFalse, undefined);
                    })
                }, "close"), React.createElement("h2", undefined, "Options for " + Data_Player$Coronate.fullName(p) + ""), React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      var fn = function (output) {
                        var scoreAdjustment = output.scoreAdjustment;
                        if (scoreAdjustment !== 0) {
                          Curry._1(setTourney, {
                                id: tourney.id,
                                name: tourney.name,
                                date: tourney.date,
                                playerIds: tourney.playerIds,
                                scoreAdjustments: Belt_Map.set(tourney.scoreAdjustments, p.id, scoreAdjustment),
                                byeQueue: tourney.byeQueue,
                                tieBreaks: tourney.tieBreaks,
                                roundList: tourney.roundList
                              });
                        } else {
                          Curry._1(setTourney, {
                                id: tourney.id,
                                name: tourney.name,
                                date: tourney.date,
                                playerIds: tourney.playerIds,
                                scoreAdjustments: Belt_Map.remove(tourney.scoreAdjustments, p.id),
                                byeQueue: tourney.byeQueue,
                                tieBreaks: tourney.tieBreaks,
                                roundList: tourney.roundList
                              });
                        }
                        Curry._1(dialog.setFalse, undefined);
                      };
                      Curry._1(form.dispatch, {
                            TAG: /* Submit */1,
                            _0: fn
                          });
                    })
                }, React.createElement("h3", undefined, React.createElement("label", {
                          className: "title-30",
                          htmlFor: Data_Id$Coronate.toString(p.id) + "-scoreAdjustment"
                        }, "Score adjustment")), React.createElement("p", {
                      className: "caption-30",
                      id: Data_Id$Coronate.toString(p.id) + "-scoreAdjustment-description"
                    }, "Score adjustment will be added to this player's actual score.\r\n              It can be negative."), React.createElement("p", undefined, React.createElement("input", {
                          "aria-describedby": Data_Id$Coronate.toString(p.id) + "-scoreAdjustment-description",
                          id: Data_Id$Coronate.toString(p.id) + "-scoreAdjustment",
                          disabled: submitting(form),
                          size: 3,
                          step: 0.5,
                          type: "number",
                          value: input(form).scoreAdjustment,
                          onBlur: (function (param) {
                              Curry._1(form.dispatch, /* BlurScoreAdjustmentField */0);
                            }),
                          onChange: (function ($$event) {
                              updateScoreAdjustment(form, $$event.target.value);
                            })
                        }), " ", React.createElement("button", {
                          className: "button-micro",
                          onClick: (function ($$event) {
                              $$event.preventDefault();
                              updateScoreAdjustment(form, "0");
                            })
                        }, "Reset")), errorNotification(scoreAdjustmentResult(form)), React.createElement("p", undefined, React.createElement("input", {
                          disabled: submitting(form) || !valid(form),
                          type: "submit",
                          value: "Save"
                        }))));
}

function PageTourneyPlayers$OptionsForm(Props) {
  var setTourney = Props.setTourney;
  var tourney = Props.tourney;
  var byeQueue = Props.byeQueue;
  var p = Props.p;
  var dialog = Hooks$Coronate.useBool(false);
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: "button-micro",
                  disabled: byeQueue.includes(p.id),
                  onClick: (function (param) {
                      Curry._1(setTourney, {
                            id: tourney.id,
                            name: tourney.name,
                            date: tourney.date,
                            playerIds: tourney.playerIds,
                            scoreAdjustments: tourney.scoreAdjustments,
                            byeQueue: Belt_Array.concat(byeQueue, [p.id]),
                            tieBreaks: tourney.tieBreaks,
                            roundList: tourney.roundList
                          });
                    })
                }, "Bye signup"), " ", React.createElement("button", {
                  className: "button-micro",
                  onClick: (function (param) {
                      Curry._1(dialog.setTrue, undefined);
                    })
                }, React.createElement("span", {
                      "aria-hidden": true
                    }, React.createElement(ReactFeather.MoreHorizontal, {})), React.createElement(VisuallyHidden, {
                      children: "More options for " + Data_Player$Coronate.fullName(p) + ""
                    })), React.createElement(Externals$Coronate.Dialog.make, {
                  isOpen: dialog.state,
                  onDismiss: dialog.setFalse,
                  ariaLabel: "Options for " + Data_Player$Coronate.fullName(p) + "",
                  children: React.createElement(PageTourneyPlayers$OptionsForm$More, {
                        setTourney: setTourney,
                        dialog: dialog,
                        tourney: tourney,
                        p: p
                      }),
                  className: ""
                }));
}

function PageTourneyPlayers$PlayerList(Props) {
  var players = Props.players;
  var tourney = Props.tourney;
  var setTourney = Props.setTourney;
  var byeQueue = Props.byeQueue;
  return React.createElement(React.Fragment, undefined, Belt_Array.map(players, (function (p) {
                    return React.createElement("tr", {
                                key: Data_Id$Coronate.toString(p.id),
                                className: "player " + Data_Player$Coronate.Type.toString(p.type_)
                              }, React.createElement("td", undefined, p.firstName), React.createElement("td", undefined, p.lastName), React.createElement("td", undefined, React.createElement(PageTourneyPlayers$OptionsForm, {
                                        setTourney: setTourney,
                                        tourney: tourney,
                                        byeQueue: byeQueue,
                                        p: p
                                      })));
                  })));
}

function PageTourneyPlayers(Props) {
  var tournament = Props.tournament;
  var setTourney = tournament.setTourney;
  var tourney = tournament.tourney;
  var getPlayer = tournament.getPlayer;
  var match = Hooks$Coronate.useSortedTable(Belt_Map.valuesToArray(tournament.activePlayers), sortFirstName, false);
  var byeQueue = tourney.byeQueue;
  var playerIds = tourney.playerIds;
  var tableDispatch = match[1];
  var playerTable = match[0];
  var match$1 = React.useState(function () {
        return Belt_Set.isEmpty(playerIds);
      });
  var setIsSelecting = match$1[1];
  var matches = Data_Rounds$Coronate.rounds2Matches(tourney.roundList);
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement("div", {
                  className: "toolbar"
                }, React.createElement("button", {
                      onClick: (function (param) {
                          Curry._1(setIsSelecting, (function (param) {
                                  return true;
                                }));
                        })
                    }, React.createElement(ReactFeather.Edit, {}), " Edit player roster")), React.createElement(Utils$Coronate.PanelContainer.make, {
                  children: null
                }, React.createElement(Utils$Coronate.Panel.make, {
                      children: React.createElement("table", undefined, React.createElement("caption", undefined, "Current roster"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                            children: "First name",
                                            sortColumn: sortFirstName,
                                            data: playerTable,
                                            dispatch: tableDispatch
                                          })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                            children: "Last name",
                                            sortColumn: sortLastName,
                                            data: playerTable,
                                            dispatch: tableDispatch
                                          })), React.createElement("th", undefined, "Options"))), React.createElement("tbody", {
                                className: "content"
                              }, React.createElement(PageTourneyPlayers$PlayerList, {
                                    players: playerTable.table,
                                    tourney: tourney,
                                    setTourney: setTourney,
                                    byeQueue: byeQueue
                                  }))),
                      style: {
                        flexShrink: "0"
                      }
                    }), React.createElement(Utils$Coronate.Panel.make, {
                      children: null
                    }, React.createElement("h3", undefined, "Bye queue"), byeQueue.length !== 0 ? React.createElement("table", {
                            style: {
                              width: "100%"
                            }
                          }, React.createElement("tbody", undefined, Belt_Array.map(byeQueue, (function (pId) {
                                      return React.createElement("tr", {
                                                  key: Data_Id$Coronate.toString(pId),
                                                  className: hasHadBye(matches, pId) ? "disabled" : ""
                                                }, React.createElement("td", undefined, Data_Player$Coronate.fullName(Curry._1(getPlayer, pId))), React.createElement("td", undefined, React.createElement("button", {
                                                          className: "button-micro",
                                                          onClick: (function (param) {
                                                              Curry._1(setTourney, {
                                                                    id: tourney.id,
                                                                    name: tourney.name,
                                                                    date: tourney.date,
                                                                    playerIds: tourney.playerIds,
                                                                    scoreAdjustments: tourney.scoreAdjustments,
                                                                    byeQueue: byeQueue.filter(function (id) {
                                                                          return !Data_Id$Coronate.eq(pId, id);
                                                                        }),
                                                                    tieBreaks: tourney.tieBreaks,
                                                                    roundList: tourney.roundList
                                                                  });
                                                            })
                                                        }, "Remove")));
                                    })))) : React.createElement("p", {
                            className: "caption-20"
                          }, "No one has signed up yet.")), React.createElement(Externals$Coronate.Dialog.make, {
                      isOpen: match$1[0],
                      onDismiss: (function (param) {
                          Curry._1(setIsSelecting, (function (param) {
                                  return false;
                                }));
                        }),
                      ariaLabel: "Select players",
                      children: null,
                      className: ""
                    }, React.createElement("button", {
                          className: "button-micro button-primary",
                          onClick: (function (param) {
                              Curry._1(setIsSelecting, (function (param) {
                                      return false;
                                    }));
                            })
                        }, "Done"), React.createElement(PageTourneyPlayers$Selecting, {
                          tourney: tourney,
                          setTourney: setTourney,
                          players: tournament.players,
                          playersDispatch: tournament.playersDispatch
                        }))));
}

var make = PageTourneyPlayers;

export {
  Selecting ,
  make ,
}
/* react Not a pure module */
