// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$Coronate from "../Utils.bs.js";
import * as PageRound$Coronate from "./PageRound.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as PageTourneyScores$Coronate from "./PageTourneyScores.bs.js";

function PageTournamentStatus(Props) {
  var tournament = Props.tournament;
  var tourney = tournament.tourney;
  var lastRoundId = Data_Rounds$Coronate.getLastKey(tourney.roundList);
  var lastRound = Data_Rounds$Coronate.get(tourney.roundList, lastRoundId);
  var tmp;
  if (lastRound !== undefined) {
    var matches = Caml_option.valFromOption(lastRound);
    tmp = Data_Rounds$Coronate.Round.size(matches) === 0 ? React.createElement("p", undefined, "Matched players in the current round will be shown here.") : React.createElement(PageRound$Coronate.RoundTable.make, {
            isCompact: true,
            roundId: lastRoundId,
            matches: Data_Rounds$Coronate.Round.toArray(matches),
            tournament: tournament
          });
  } else {
    tmp = React.createElement("p", undefined, "No rounds played yet.");
  }
  return React.createElement(React.Fragment, undefined, React.createElement("h2", {
                  style: {
                    textAlign: "center"
                  }
                }, "Tournament status"), React.createElement("div", {
                  className: "content-area"
                }, React.createElement(Utils$Coronate.PanelContainer.make, {
                      children: null,
                      style: {
                        justifyContent: "center"
                      }
                    }, React.createElement(Utils$Coronate.Panel.make, {
                          children: tmp
                        }), React.createElement(Utils$Coronate.Panel.make, {
                          children: React.createElement(PageTourneyScores$Coronate.ScoreTable.make, {
                                size: /* Compact */0,
                                tourney: tourney,
                                getPlayer: tournament.getPlayer,
                                title: "Rankings"
                              })
                        }))));
}

var make = PageTournamentStatus;

export {
  make ,
}
/* react Not a pure module */
