// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Data_Id$Coronate from "./Data_Id.bs.js";

function toString(x) {
  switch (x) {
    case /* WhiteWon */0 :
        return "whiteWon";
    case /* BlackWon */1 :
        return "blackWon";
    case /* Draw */2 :
        return "draw";
    case /* Aborted */3 :
        return "aborted";
    case /* WhiteAborted */4 :
        return "whiteAborted";
    case /* BlackAborted */5 :
        return "blackAborted";
    case /* NotSet */6 :
        return "notSet";
    
  }
}

function fromString(x) {
  switch (x) {
    case "aborted" :
        return /* Aborted */3;
    case "blackAborted" :
        return /* BlackAborted */5;
    case "blackWon" :
        return /* BlackWon */1;
    case "draw" :
        return /* Draw */2;
    case "whiteAborted" :
        return /* WhiteAborted */4;
    case "whiteWon" :
        return /* WhiteWon */0;
    default:
      return /* NotSet */6;
  }
}

function scoreByeMatch(white, black, byeValue, $$default) {
  var match = Data_Id$Coronate.isDummy(white);
  var match$1 = Data_Id$Coronate.isDummy(black);
  if (match) {
    switch (byeValue) {
      case /* Full */0 :
          return /* BlackWon */1;
      case /* Half */1 :
          return /* Draw */2;
      case /* Zero */2 :
          break;
      
    }
  } else if (!match$1) {
    return $$default;
  }
  if (!match$1) {
    return /* WhiteWon */0;
  }
  switch (byeValue) {
    case /* Full */0 :
        return /* WhiteWon */0;
    case /* Half */1 :
        return /* Draw */2;
    case /* Zero */2 :
        return /* BlackWon */1;
    
  }
}

function reverse(t) {
  switch (t) {
    case /* WhiteWon */0 :
        return /* BlackWon */1;
    case /* BlackWon */1 :
        return /* WhiteWon */0;
    case /* WhiteAborted */4 :
        return /* BlackAborted */5;
    case /* BlackAborted */5 :
        return /* WhiteAborted */4;
    case /* Draw */2 :
    case /* Aborted */3 :
    case /* NotSet */6 :
        return t;
    
  }
}

function isBye(param) {
  if (Data_Id$Coronate.isDummy(param.whiteId)) {
    return true;
  } else {
    return Data_Id$Coronate.isDummy(param.blackId);
  }
}

function decode(json) {
  var d = Js_json.decodeObject(json);
  var json$1 = Belt_Option.getExn(Belt_Option.flatMap(d, (function (d) {
              return Js_dict.get(d, "result");
            })));
  return {
          id: Data_Id$Coronate.decode(Belt_Option.getExn(Belt_Option.flatMap(d, (function (d) {
                          return Js_dict.get(d, "id");
                        })))),
          whiteId: Data_Id$Coronate.decode(Belt_Option.getExn(Belt_Option.flatMap(d, (function (d) {
                          return Js_dict.get(d, "whiteId");
                        })))),
          blackId: Data_Id$Coronate.decode(Belt_Option.getExn(Belt_Option.flatMap(d, (function (d) {
                          return Js_dict.get(d, "blackId");
                        })))),
          whiteNewRating: Belt_Option.getExn(Belt_Option.flatMap(Belt_Option.flatMap(d, (function (d) {
                          return Js_dict.get(d, "whiteNewRating");
                        })), Js_json.decodeNumber)) | 0,
          blackNewRating: Belt_Option.getExn(Belt_Option.flatMap(Belt_Option.flatMap(d, (function (d) {
                          return Js_dict.get(d, "blackNewRating");
                        })), Js_json.decodeNumber)) | 0,
          whiteOrigRating: Belt_Option.getExn(Belt_Option.flatMap(Belt_Option.flatMap(d, (function (d) {
                          return Js_dict.get(d, "whiteOrigRating");
                        })), Js_json.decodeNumber)) | 0,
          blackOrigRating: Belt_Option.getExn(Belt_Option.flatMap(Belt_Option.flatMap(d, (function (d) {
                          return Js_dict.get(d, "blackOrigRating");
                        })), Js_json.decodeNumber)) | 0,
          result: fromString(Belt_Option.getExn(Js_json.decodeString(json$1)))
        };
}

function encode(data) {
  return Js_dict.fromArray([
              [
                "id",
                Data_Id$Coronate.encode(data.id)
              ],
              [
                "whiteId",
                Data_Id$Coronate.encode(data.whiteId)
              ],
              [
                "blackId",
                Data_Id$Coronate.encode(data.blackId)
              ],
              [
                "whiteNewRating",
                data.whiteNewRating
              ],
              [
                "blackNewRating",
                data.blackNewRating
              ],
              [
                "whiteOrigRating",
                data.whiteOrigRating
              ],
              [
                "blackOrigRating",
                data.blackOrigRating
              ],
              [
                "result",
                toString(data.result)
              ]
            ]);
}

function manualPair(white, black, result, byeValue) {
  return {
          id: Data_Id$Coronate.random(undefined),
          whiteId: white.id,
          blackId: black.id,
          whiteNewRating: white.rating,
          blackNewRating: black.rating,
          whiteOrigRating: white.rating,
          blackOrigRating: black.rating,
          result: result >= 6 ? scoreByeMatch(white.id, black.id, byeValue, /* NotSet */6) : result
        };
}

function swapColors(match) {
  var x = match.result;
  var tmp;
  switch (x) {
    case /* WhiteWon */0 :
        tmp = /* BlackWon */1;
        break;
    case /* BlackWon */1 :
        tmp = /* WhiteWon */0;
        break;
    case /* WhiteAborted */4 :
        tmp = /* BlackAborted */5;
        break;
    case /* BlackAborted */5 :
        tmp = /* WhiteAborted */4;
        break;
    case /* Draw */2 :
    case /* Aborted */3 :
    case /* NotSet */6 :
        tmp = x;
        break;
    
  }
  return {
          id: match.id,
          whiteId: match.blackId,
          blackId: match.whiteId,
          whiteNewRating: match.blackNewRating,
          blackNewRating: match.whiteNewRating,
          whiteOrigRating: match.blackOrigRating,
          blackOrigRating: match.whiteOrigRating,
          result: tmp
        };
}

var Result = {
  toString: toString,
  fromString: fromString,
  scoreByeMatch: scoreByeMatch,
  reverse: reverse
};

export {
  Result ,
  isBye ,
  decode ,
  encode ,
  manualPair ,
  swapColors ,
}
/* Data_Id-Coronate Not a pure module */
