// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as Data_Config$Coronate from "../Data/Data_Config.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";

var nat = Data_Player$Coronate.NatInt.fromInt;

var batman = Data_Id$Coronate.fromString("BruceWayne_lv_ZsUHTU9");

var robin = Data_Id$Coronate.fromString("DickGrayson_1C2rCokHH");

var alfred = Data_Id$Coronate.fromString("AlfredPennyworth_y4dW");

var barbara = Data_Id$Coronate.fromString("BarbaraGordon_cL6SpI2");

var batwoman = Data_Id$Coronate.fromString("KateKane_klFW6gDfUOTX");

var catwoman = Data_Id$Coronate.fromString("SelinaKyle_rJBH-45Xoy");

var jason = Data_Id$Coronate.fromString("JasonTodd_fc9CeOa-Luw");

var james = Data_Id$Coronate.fromString("JamesGordon_1ts9xICT3");

var huntress = Data_Id$Coronate.fromString("HelenaWayne_fE6O0DJcE");

var joker = Data_Id$Coronate.fromString("Joker_v0z2416fpAZ9o2c");

var harley = Data_Id$Coronate.fromString("HarleyQuinn_-10-02VPH");

var freeze = Data_Id$Coronate.fromString("VictorFries_cWaQoW014");

var penguin = Data_Id$Coronate.fromString("OswaldCobblepot_lfCro");

var ras = Data_Id$Coronate.fromString("RasAlGhul_k9n8k852bHr");

var poisonivy = Data_Id$Coronate.fromString("PamelaIsley_vH5vD8uPB");

var riddler = Data_Id$Coronate.fromString("EdwardNigma_j80JfWOZq");

var scarecrow = Data_Id$Coronate.fromString("JonathanCrane_R4Q8tVW");

var twoface = Data_Id$Coronate.fromString("HarveyDent_0eYIiP_Ij5");

var hugo = Data_Id$Coronate.fromString("HugoStrange_az43f9mtS");

var config_avoidPairs = Belt_Set.fromArray(Belt_Array.keepMap([
          [
            barbara,
            james
          ],
          [
            joker,
            harley
          ],
          [
            huntress,
            batman
          ]
        ], (function (param) {
            return Data_Id$Coronate.Pair.make(param[0], param[1]);
          })), Data_Id$Coronate.Pair.id);

var config_lastBackup = new Date("1970-01-01T00:00:00.000Z");

var config = {
  avoidPairs: config_avoidPairs,
  byeValue: /* Full */0,
  lastBackup: config_lastBackup,
  whiteAlias: undefined,
  blackAlias: undefined
};

var players = Belt_Map.fromArray([
      [
        batman,
        {
          firstName: "Bruce",
          id: batman,
          lastName: "Wayne",
          matchCount: Curry._1(nat, 9),
          rating: 1998,
          type_: /* Person */0
        }
      ],
      [
        robin,
        {
          firstName: "Dick",
          id: robin,
          lastName: "Grayson",
          matchCount: Curry._1(nat, 9),
          rating: 1909,
          type_: /* Person */0
        }
      ],
      [
        alfred,
        {
          firstName: "Alfred",
          id: alfred,
          lastName: "Pennyworth",
          matchCount: Curry._1(nat, 9),
          rating: 1999,
          type_: /* Person */0
        }
      ],
      [
        barbara,
        {
          firstName: "Barbara",
          id: barbara,
          lastName: "Gordon",
          matchCount: Curry._1(nat, 7),
          rating: 1345,
          type_: /* Person */0
        }
      ],
      [
        batwoman,
        {
          firstName: "Kate",
          id: batwoman,
          lastName: "Kane",
          matchCount: Curry._1(nat, 9),
          rating: 1539,
          type_: /* Person */0
        }
      ],
      [
        catwoman,
        {
          firstName: "Selina",
          id: catwoman,
          lastName: "Kyle",
          matchCount: Curry._1(nat, 9),
          rating: 1495,
          type_: /* Person */0
        }
      ],
      [
        jason,
        {
          firstName: "Jason",
          id: jason,
          lastName: "Todd",
          matchCount: Curry._1(nat, 7),
          rating: 1101,
          type_: /* Person */0
        }
      ],
      [
        james,
        {
          firstName: "James",
          id: james,
          lastName: "Gordon",
          matchCount: Curry._1(nat, 7),
          rating: 1167,
          type_: /* Person */0
        }
      ],
      [
        huntress,
        {
          firstName: "Helena",
          id: huntress,
          lastName: "Wayne",
          matchCount: Curry._1(nat, 7),
          rating: 1087,
          type_: /* Person */0
        }
      ],
      [
        joker,
        {
          firstName: "Joker",
          id: joker,
          lastName: "",
          matchCount: Curry._1(nat, 1),
          rating: 1538,
          type_: /* Person */0
        }
      ],
      [
        harley,
        {
          firstName: "Harley",
          id: harley,
          lastName: "Quinn",
          matchCount: Curry._1(nat, 1),
          rating: 1648,
          type_: /* Person */0
        }
      ],
      [
        freeze,
        {
          firstName: "Victor",
          id: freeze,
          lastName: "Fries",
          matchCount: Curry._1(nat, 1),
          rating: 862,
          type_: /* Person */0
        }
      ],
      [
        penguin,
        {
          firstName: "Oswald",
          id: penguin,
          lastName: "Cobblepot",
          matchCount: Curry._1(nat, 1),
          rating: 1812,
          type_: /* Person */0
        }
      ],
      [
        ras,
        {
          firstName: "Ra's",
          id: ras,
          lastName: "al Ghul",
          matchCount: Curry._1(nat, 1),
          rating: 1404,
          type_: /* Person */0
        }
      ],
      [
        poisonivy,
        {
          firstName: "Pamela",
          id: poisonivy,
          lastName: "Isley",
          matchCount: Curry._1(nat, 1),
          rating: 965,
          type_: /* Person */0
        }
      ],
      [
        riddler,
        {
          firstName: "Edward",
          id: riddler,
          lastName: "Nigma",
          matchCount: Curry._1(nat, 1),
          rating: 948,
          type_: /* Person */0
        }
      ],
      [
        scarecrow,
        {
          firstName: "Jonathan",
          id: scarecrow,
          lastName: "Crane",
          matchCount: Curry._1(nat, 1),
          rating: 899,
          type_: /* Person */0
        }
      ],
      [
        twoface,
        {
          firstName: "Harvey",
          id: twoface,
          lastName: "Dent",
          matchCount: Curry._1(nat, 1),
          rating: 1649,
          type_: /* Person */0
        }
      ],
      [
        hugo,
        {
          firstName: "Hugo",
          id: hugo,
          lastName: "Strange",
          matchCount: Curry._1(nat, 0),
          rating: 800,
          type_: /* Person */0
        }
      ]
    ], Data_Id$Coronate.id);

var tournaments = Belt_Map.fromArray([
      [
        Data_Id$Coronate.fromString("CaouTNel9k70jUJ0h6SYM"),
        {
          id: Data_Id$Coronate.fromString("CaouTNel9k70jUJ0h6SYM"),
          name: "Wayne Manor Open",
          date: new Date("2019-05-22T12:14:47.670Z"),
          playerIds: Belt_Set.fromArray([
                batman,
                robin,
                alfred,
                barbara,
                batwoman,
                catwoman,
                jason,
                james,
                huntress
              ], Data_Id$Coronate.id),
          scoreAdjustments: Belt_Map.make(Data_Id$Coronate.id),
          byeQueue: [],
          tieBreaks: [
            /* Median */0,
            /* Solkoff */1,
            /* Cumulative */2,
            /* CumulativeOfOpposition */3
          ],
          roundList: Data_Rounds$Coronate.fromArray([
                Data_Rounds$Coronate.Round.fromArray([
                      {
                        id: Data_Id$Coronate.fromString("FUASEeyES6ez_ROoT6qmU"),
                        whiteId: jason,
                        blackId: robin,
                        whiteNewRating: 1236,
                        blackNewRating: 2109,
                        whiteOrigRating: 1238,
                        blackOrigRating: 2108,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("gqPyD66QMPF-pup41xsB2"),
                        whiteId: james,
                        blackId: alfred,
                        whiteNewRating: 1049,
                        blackNewRating: 2260,
                        whiteOrigRating: 1049,
                        blackOrigRating: 2260,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("KpS1lQSzsQWQ3VVWJyA2P"),
                        whiteId: batwoman,
                        blackId: barbara,
                        whiteNewRating: 1553,
                        blackNewRating: 1722,
                        whiteOrigRating: 1527,
                        blackOrigRating: 1755,
                        result: /* Draw */2
                      },
                      {
                        id: Data_Id$Coronate.fromString("OgFuy-wq8mz378EWat46u"),
                        whiteId: catwoman,
                        blackId: batman,
                        whiteNewRating: 1278,
                        blackNewRating: 1887,
                        whiteOrigRating: 1284,
                        blackOrigRating: 1881,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("f8Ps3GUmd0ZRsBBY8rZOp"),
                        whiteId: huntress,
                        blackId: Data_Id$Coronate.dummy,
                        whiteNewRating: 831,
                        blackNewRating: 0,
                        whiteOrigRating: 831,
                        blackOrigRating: 0,
                        result: /* WhiteWon */0
                      }
                    ]),
                Data_Rounds$Coronate.Round.fromArray([
                      {
                        id: Data_Id$Coronate.fromString("6seKrw7ehbhL766g6L2PF"),
                        whiteId: robin,
                        blackId: batwoman,
                        whiteNewRating: 2122,
                        blackNewRating: 1672,
                        whiteOrigRating: 2109,
                        blackOrigRating: 1685,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("TCSjz48ZXqjamtYUFNg0B"),
                        whiteId: batman,
                        blackId: huntress,
                        whiteNewRating: 1887,
                        blackNewRating: 830,
                        whiteOrigRating: 1887,
                        blackOrigRating: 831,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("zF64DEsN8sHydpDDsg37E"),
                        whiteId: alfred,
                        blackId: catwoman,
                        whiteNewRating: 2101,
                        blackNewRating: 1437,
                        whiteOrigRating: 2260,
                        blackOrigRating: 1278,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("qVGt1EJq9y0MmvFtumM0A"),
                        whiteId: barbara,
                        blackId: jason,
                        whiteNewRating: 1574,
                        blackNewRating: 1207,
                        whiteOrigRating: 1545,
                        blackOrigRating: 1236,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("UhfHaRWr_-BtVo22xAuJu"),
                        whiteId: james,
                        blackId: Data_Id$Coronate.dummy,
                        whiteNewRating: 1049,
                        blackNewRating: 0,
                        whiteOrigRating: 1049,
                        blackOrigRating: 0,
                        result: /* WhiteWon */0
                      }
                    ]),
                Data_Rounds$Coronate.Round.fromArray([
                      {
                        id: Data_Id$Coronate.fromString("odrOOnZJUe0YAwkfUDqUb"),
                        whiteId: alfred,
                        blackId: batman,
                        whiteNewRating: 1998,
                        blackNewRating: 1990,
                        whiteOrigRating: 2101,
                        blackOrigRating: 1887,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("qzCMqUwNIDAcFSAuA5yCm"),
                        whiteId: huntress,
                        blackId: robin,
                        whiteNewRating: 990,
                        blackNewRating: 2008,
                        whiteOrigRating: 830,
                        blackOrigRating: 2122,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("6QgVqdtcJPjfVp3UZ8S9g"),
                        whiteId: catwoman,
                        blackId: barbara,
                        whiteNewRating: 1529,
                        blackNewRating: 1464,
                        whiteOrigRating: 1437,
                        blackOrigRating: 1574,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("as45gODKMLC5-3_UsTyx5"),
                        whiteId: batwoman,
                        blackId: james,
                        whiteNewRating: 1542,
                        blackNewRating: 1244,
                        whiteOrigRating: 1672,
                        blackOrigRating: 1049,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("Pc0CWecSfeGNfvBPjyEIj"),
                        whiteId: jason,
                        blackId: Data_Id$Coronate.dummy,
                        whiteNewRating: 1207,
                        blackNewRating: 0,
                        whiteOrigRating: 1207,
                        blackOrigRating: 0,
                        result: /* WhiteWon */0
                      }
                    ]),
                Data_Rounds$Coronate.Round.fromArray([
                      {
                        id: Data_Id$Coronate.fromString("xj0y_Iqkb-g3MDGgmYx2-"),
                        whiteId: batman,
                        blackId: batwoman,
                        whiteNewRating: 1998,
                        blackNewRating: 1534,
                        whiteOrigRating: 1990,
                        blackOrigRating: 1542,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("HWYWtsyaqUkHRExM6kQrt"),
                        whiteId: robin,
                        blackId: james,
                        whiteNewRating: 2009,
                        blackNewRating: 1243,
                        whiteOrigRating: 2008,
                        blackOrigRating: 1244,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("uAzHZVMC71liQZ-6fWWeD"),
                        whiteId: huntress,
                        blackId: catwoman,
                        whiteNewRating: 983,
                        blackNewRating: 1534,
                        whiteOrigRating: 990,
                        blackOrigRating: 1529,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("_tCBn9YNIyto-vXpxm7WI"),
                        whiteId: jason,
                        blackId: alfred,
                        whiteNewRating: 1205,
                        blackNewRating: 1999,
                        whiteOrigRating: 1207,
                        blackOrigRating: 1998,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("L7yatE2oVKlV7LOY6-d7Y"),
                        whiteId: barbara,
                        blackId: Data_Id$Coronate.dummy,
                        whiteNewRating: 1464,
                        blackNewRating: 0,
                        whiteOrigRating: 1464,
                        blackOrigRating: 0,
                        result: /* WhiteWon */0
                      }
                    ])
              ])
        }
      ],
      [
        Data_Id$Coronate.fromString("tvAdS4YbSOznrBgrg0ITA"),
        {
          id: Data_Id$Coronate.fromString("tvAdS4YbSOznrBgrg0ITA"),
          name: "The Battle for Gotham City",
          date: new Date("2019-05-29T12:15:20.593Z"),
          playerIds: Belt_Set.fromArray([
                batman,
                robin,
                alfred,
                barbara,
                batwoman,
                catwoman,
                jason,
                james,
                huntress,
                joker,
                harley,
                freeze,
                penguin,
                ras,
                poisonivy,
                riddler,
                scarecrow,
                twoface
              ], Data_Id$Coronate.id),
          scoreAdjustments: Belt_Map.make(Data_Id$Coronate.id),
          byeQueue: [],
          tieBreaks: [
            /* Median */0,
            /* Solkoff */1,
            /* Cumulative */2,
            /* CumulativeOfOpposition */3
          ],
          roundList: Data_Rounds$Coronate.fromArray([
                Data_Rounds$Coronate.Round.fromArray([
                      {
                        id: Data_Id$Coronate.fromString("5f8GYcR8V44NYvTN1cZle"),
                        whiteId: riddler,
                        blackId: batman,
                        whiteNewRating: 948,
                        blackNewRating: 1998,
                        whiteOrigRating: 950,
                        blackOrigRating: 1998,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("GPTct4sL368SryTLFUu8E"),
                        whiteId: scarecrow,
                        blackId: alfred,
                        whiteNewRating: 899,
                        blackNewRating: 1999,
                        whiteOrigRating: 900,
                        blackOrigRating: 1999,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("AxtoztZ6O19nyrLfZ4YaU"),
                        whiteId: twoface,
                        blackId: robin,
                        whiteNewRating: 1649,
                        blackNewRating: 1909,
                        whiteOrigRating: 850,
                        blackOrigRating: 2009,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("bUM_tWQsAtPe1gqRzlXd1"),
                        whiteId: ras,
                        blackId: catwoman,
                        whiteNewRating: 1404,
                        blackNewRating: 1495,
                        whiteOrigRating: 1050,
                        blackOrigRating: 1534,
                        result: /* Draw */2
                      },
                      {
                        id: Data_Id$Coronate.fromString("bAOVlP-M5xaPk1qofNReb"),
                        whiteId: penguin,
                        blackId: barbara,
                        whiteNewRating: 1812,
                        blackNewRating: 1345,
                        whiteOrigRating: 1100,
                        blackOrigRating: 1464,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("4omlgiGSaE1BmrHdABSym"),
                        whiteId: poisonivy,
                        blackId: batwoman,
                        whiteNewRating: 965,
                        blackNewRating: 1539,
                        whiteOrigRating: 1000,
                        blackOrigRating: 1534,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("ysdEVYS2AyuKyOAwLLpTF"),
                        whiteId: harley,
                        blackId: james,
                        whiteNewRating: 1648,
                        blackNewRating: 1167,
                        whiteOrigRating: 1200,
                        blackOrigRating: 1242,
                        result: /* WhiteWon */0
                      },
                      {
                        id: Data_Id$Coronate.fromString("YoJ9WGokAYrmJjfxCCf87"),
                        whiteId: freeze,
                        blackId: joker,
                        whiteNewRating: 862,
                        blackNewRating: 1538,
                        whiteOrigRating: 1150,
                        blackOrigRating: 1250,
                        result: /* BlackWon */1
                      },
                      {
                        id: Data_Id$Coronate.fromString("Az7SBl3cs7rbwKPBI0IsU"),
                        whiteId: huntress,
                        blackId: jason,
                        whiteNewRating: 1087,
                        blackNewRating: 1101,
                        whiteOrigRating: 983,
                        blackOrigRating: 1205,
                        result: /* WhiteWon */0
                      }
                    ]),
                Data_Rounds$Coronate.Round.empty
              ])
        }
      ]
    ], Data_Id$Coronate.id);

export {
  config ,
  players ,
  tournaments ,
}
/* batman Not a pure module */
