// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Numeral from "numeral";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Tabs from "@reach/tabs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactFeather from "react-feather";
import * as Hooks$Coronate from "../Hooks.bs.js";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as HelpDialogs$Coronate from "../HelpDialogs.bs.js";
import * as Data_Scoring$Coronate from "../Data/Data_Scoring.bs.js";
import * as HtmlEntities$Coronate from "../HtmlEntities.bs.js";
import VisuallyHidden from "@reach/visually-hidden";

function PageTourneyScores$ScoreTable(Props) {
  var size = Props.size;
  var tourney = Props.tourney;
  var getPlayer = Props.getPlayer;
  var title = Props.title;
  var tieBreaks = tourney.tieBreaks;
  var standingTree = Data_Scoring$Coronate.createStandingTree(Belt_Array.keep(Data_Scoring$Coronate.createStandingArray(Data_Scoring$Coronate.fromTournament(tourney.roundList, tourney.scoreAdjustments), tieBreaks), (function (param) {
              return !Data_Id$Coronate.isDummy(param.id);
            })), tieBreaks);
  return React.createElement("table", {
              className: "pagescores__table"
            }, React.createElement("caption", {
                  className: size ? "title-40" : "title-30"
                }, title), React.createElement("thead", undefined, React.createElement("tr", {
                      className: "pagescores__topheader"
                    }, React.createElement("th", {
                          className: "title-10",
                          scope: "col"
                        }, "Rank"), React.createElement("th", {
                          className: "title-10",
                          scope: "col"
                        }, "Name"), React.createElement("th", {
                          className: "title-10",
                          scope: "col"
                        }, "Score"), size ? Belt_Array.map(tieBreaks, (function (tb) {
                              return React.createElement("th", {
                                          key: Data_Scoring$Coronate.TieBreak.toString(tb),
                                          className: "title-10",
                                          scope: "col"
                                        }, Data_Scoring$Coronate.TieBreak.toPrettyString(tb));
                            })) : null)), React.createElement("tbody", undefined, Belt_Array.mapWithIndex(Belt_Array.reverse(Belt_List.toArray(standingTree)), (function (rank, standingsFlat) {
                        return Belt_Array.mapWithIndex(Belt_Array.reverse(Belt_List.toArray(standingsFlat)), (function (i, standing) {
                                      return React.createElement("tr", {
                                                  key: Data_Id$Coronate.toString(standing.id),
                                                  className: "pagescores__row"
                                                }, i === 0 ? React.createElement("th", {
                                                        className: "table__number pagescores__number pagescores__rank pagescores__row-th",
                                                        rowSpan: Belt_List.size(standingsFlat),
                                                        scope: "row"
                                                      }, rank + 1 | 0) : null, size ? React.createElement(Utils$Coronate.TestId.make, {
                                                        children: React.createElement("th", {
                                                              className: "pagescores__row-th pagescores__playername",
                                                              scope: "row"
                                                            }, Data_Player$Coronate.fullName(Curry._1(getPlayer, standing.id))),
                                                        testId: "rank-" + (String(rank + 1 | 0) + ("." + String(i)))
                                                      }) : React.createElement("td", {
                                                        className: "pagescores__row-td pagescores__playername"
                                                      }, Data_Player$Coronate.fullName(Curry._1(getPlayer, standing.id))), React.createElement("td", {
                                                      className: "pagescores__number pagescores__row-td table__number"
                                                    }, Curry._1(Data_Scoring$Coronate.Score.Sum.toNumeral, standing.score).format("1/2")), size ? Belt_Array.map(tieBreaks, (function (tb) {
                                                          return React.createElement("td", {
                                                                      key: Data_Scoring$Coronate.TieBreak.toString(tb),
                                                                      className: "pagescores__row-td table__number"
                                                                    }, Curry._1(Data_Scoring$Coronate.Score.Sum.toNumeral, Data_Scoring$Coronate.getTieBreak(standing, tb)).format("1/2"));
                                                        })) : null);
                                    }));
                      }))));
}

var ScoreTable = {
  make: PageTourneyScores$ScoreTable
};

function PageTourneyScores$SelectTieBreaks(Props) {
  var tourney = Props.tourney;
  var setTourney = Props.setTourney;
  var tieBreaks = tourney.tieBreaks;
  var match = React.useState(function () {
        
      });
  var setSelectedTb = match[1];
  var selectedTb = match[0];
  var defaultId = function (x) {
    if (x !== undefined) {
      return x;
    } else if (selectedTb !== undefined) {
      return selectedTb;
    } else {
      return /* Median */0;
    }
  };
  var toggleTb = function (id) {
    if (tieBreaks.includes(defaultId(id))) {
      Curry._1(setTourney, {
            id: tourney.id,
            name: tourney.name,
            date: tourney.date,
            playerIds: tourney.playerIds,
            scoreAdjustments: tourney.scoreAdjustments,
            byeQueue: tourney.byeQueue,
            tieBreaks: tourney.tieBreaks.filter(function (tbId) {
                  return defaultId(id) !== tbId;
                }),
            roundList: tourney.roundList
          });
      return Curry._1(setSelectedTb, (function (param) {
                    
                  }));
    } else {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks.concat([defaultId(id)]),
                  roundList: tourney.roundList
                });
    }
  };
  var moveTb = function (direction) {
    if (selectedTb === undefined) {
      return ;
    }
    var index = tieBreaks.indexOf(selectedTb);
    Curry._1(setTourney, {
          id: tourney.id,
          name: tourney.name,
          date: tourney.date,
          playerIds: tourney.playerIds,
          scoreAdjustments: tourney.scoreAdjustments,
          byeQueue: tourney.byeQueue,
          tieBreaks: Utils$Coronate.$$Array.swap(tourney.tieBreaks, index, index + direction | 0),
          roundList: tourney.roundList
        });
  };
  return React.createElement(Utils$Coronate.PanelContainer.make, {
              children: null,
              className: "content-area"
            }, React.createElement(Utils$Coronate.Panel.make, {
                  children: null
                }, React.createElement("div", {
                      className: "toolbar"
                    }, React.createElement("button", {
                          className: "button-micro",
                          disabled: selectedTb === undefined,
                          onClick: (function (param) {
                              toggleTb(undefined);
                            })
                        }, "Remove"), React.createElement("button", {
                          className: "button-micro",
                          disabled: selectedTb === undefined,
                          onClick: (function (param) {
                              moveTb(-1);
                            })
                        }, React.createElement(ReactFeather.ArrowUp, {}), " Move up"), React.createElement("button", {
                          className: "button-micro",
                          disabled: selectedTb === undefined,
                          onClick: (function (param) {
                              moveTb(1);
                            })
                        }, React.createElement(ReactFeather.ArrowDown, {}), " Move down"), React.createElement("button", {
                          className: Cn.append("button-micro", Cn.onSome("button-primary", selectedTb)),
                          disabled: selectedTb === undefined,
                          onClick: (function (param) {
                              Curry._1(setSelectedTb, (function (param) {
                                      
                                    }));
                            })
                        }, "Done")), React.createElement("table", undefined, React.createElement("caption", {
                          className: "title-30"
                        }, "Selected tiebreak methods"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "Name"), React.createElement("th", undefined, React.createElement(VisuallyHidden, {
                                      children: "Controls"
                                    })))), React.createElement("tbody", {
                          className: "content"
                        }, Belt_Array.map(tieBreaks, (function (tieBreak) {
                                return React.createElement("tr", {
                                            key: Data_Scoring$Coronate.TieBreak.toString(tieBreak),
                                            className: Belt_Option.mapWithDefault(selectedTb, "", (function (x) {
                                                    if (x === tieBreak) {
                                                      return "selected";
                                                    } else {
                                                      return "";
                                                    }
                                                  }))
                                          }, React.createElement("td", undefined, Data_Scoring$Coronate.TieBreak.toPrettyString(tieBreak)), React.createElement("td", {
                                                style: {
                                                  width: "48px"
                                                }
                                              }, React.createElement("button", {
                                                    className: "button-micro",
                                                    disabled: selectedTb !== undefined && selectedTb !== tieBreak,
                                                    onClick: (function (param) {
                                                        if (selectedTb !== undefined) {
                                                          if (selectedTb === tieBreak) {
                                                            return Curry._1(setSelectedTb, (function (param) {
                                                                          
                                                                        }));
                                                          } else {
                                                            return Curry._1(setSelectedTb, (function (param) {
                                                                          return tieBreak;
                                                                        }));
                                                          }
                                                        } else {
                                                          return Curry._1(setSelectedTb, (function (param) {
                                                                        return tieBreak;
                                                                      }));
                                                        }
                                                      })
                                                  }, selectedTb !== undefined && selectedTb === tieBreak ? "Done" : "Edit")));
                              }))))), React.createElement(Utils$Coronate.Panel.make, {
                  children: null
                }, React.createElement("div", {
                      className: "toolbar"
                    }, HtmlEntities$Coronate.nbsp), React.createElement("table", {
                      style: {
                        marginTop: "16px"
                      }
                    }, React.createElement("caption", {
                          className: "title-30"
                        }, "Available tiebreak methods"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "Name"), React.createElement("th", undefined, React.createElement(VisuallyHidden, {
                                      children: "Controls"
                                    })))), React.createElement("tbody", {
                          className: "content"
                        }, Belt_Array.map([
                              /* Median */0,
                              /* Solkoff */1,
                              /* Cumulative */2,
                              /* CumulativeOfOpposition */3,
                              /* MostBlack */4
                            ], (function (tieBreak) {
                                return React.createElement("tr", {
                                            key: Data_Scoring$Coronate.TieBreak.toString(tieBreak)
                                          }, React.createElement("td", undefined, React.createElement("span", {
                                                    className: tieBreaks.includes(tieBreak) ? "disabled" : "enabled"
                                                  }, Data_Scoring$Coronate.TieBreak.toPrettyString(tieBreak))), React.createElement("td", undefined, tieBreaks.includes(tieBreak) ? null : React.createElement("button", {
                                                      className: "button-micro",
                                                      onClick: (function (param) {
                                                          toggleTb(tieBreak);
                                                        })
                                                    }, "Add")));
                              }))))));
}

function PageTourneyScores(Props) {
  var tournament = Props.tournament;
  var tourney = tournament.tourney;
  var helpDialog = Hooks$Coronate.useBool(false);
  return React.createElement("div", undefined, React.createElement(Tabs.Tabs, {
                  children: null
                }, React.createElement(Tabs.TabList, {
                      children: null
                    }, React.createElement(Tabs.Tab, {
                          children: null
                        }, React.createElement(ReactFeather.List, {}), " Scores"), React.createElement(Tabs.Tab, {
                          children: null
                        }, React.createElement(ReactFeather.Settings, {}), " Edit tiebreak rules")), React.createElement(Tabs.TabPanels, {
                      children: null
                    }, React.createElement(Tabs.TabPanel, {
                          children: React.createElement(PageTourneyScores$ScoreTable, {
                                size: /* Expanded */1,
                                tourney: tourney,
                                getPlayer: tournament.getPlayer,
                                title: "Score detail"
                              })
                        }), React.createElement(Tabs.TabPanel, {
                          children: React.createElement(PageTourneyScores$SelectTieBreaks, {
                                tourney: tourney,
                                setTourney: tournament.setTourney
                              })
                        }))), React.createElement("div", {
                  className: "toolbar"
                }, React.createElement("button", {
                      onClick: (function (param) {
                          Curry._1(helpDialog.setTrue, undefined);
                        })
                    }, React.createElement(ReactFeather.HelpCircle, {}), " Tiebreak method information")), React.createElement(HelpDialogs$Coronate.TieBreaks.make, {
                  state: helpDialog,
                  ariaLabel: "Tiebreak method information"
                }));
}

function getXScore(scoreData, player1Id, player2Id) {
  if (Data_Id$Coronate.eq(player1Id, player2Id)) {
    return React.createElement(ReactFeather.X, {
                className: "pagescores__x"
              });
  }
  var scoreData$1 = Belt_Map.get(scoreData, player1Id);
  if (scoreData$1 === undefined) {
    return null;
  }
  var result = Data_Scoring$Coronate.oppResultsToSumById(scoreData$1, player2Id);
  if (result !== undefined) {
    return Curry._1(Data_Scoring$Coronate.Score.Sum.toNumeral, Caml_option.valFromOption(result)).format("1/2");
  } else {
    return null;
  }
}

function getRatingChangeTds(scoreData, playerId) {
  var firstRating = Belt_Option.getWithDefault(Belt_Option.map(Belt_Map.get(scoreData, playerId), (function (x) {
              return x.firstRating;
            })), 0);
  var lastRating = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Map.get(scoreData, playerId), (function (x) {
              return Belt_List.head(x.ratings);
            })), firstRating);
  var change = Numeral(lastRating - firstRating | 0).format("+0");
  return React.createElement(React.Fragment, undefined, React.createElement("td", {
                  className: "pagescores__row-td table__number"
                }, lastRating), React.createElement("td", {
                  className: "pagescores__row-td table__number body-10"
                }, change));
}

function PageTourneyScores$Crosstable(Props) {
  var param = Props.tournament;
  var match = param.tourney;
  var getPlayer = param.getPlayer;
  var scoreData = Data_Scoring$Coronate.fromTournament(match.roundList, match.scoreAdjustments);
  var standings = Data_Scoring$Coronate.createStandingArray(scoreData, match.tieBreaks);
  return React.createElement("table", {
              className: "pagescores__table"
            }, React.createElement("caption", undefined, "Crosstable"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "#"), React.createElement("th", undefined, "Name"), Belt_Array.mapWithIndex(standings, (function (rank, param) {
                            return React.createElement("th", {
                                        key: String(rank)
                                      }, rank + 1 | 0);
                          })), React.createElement("th", undefined, "Score"), React.createElement("th", {
                          colSpan: 2
                        }, "Rating"))), React.createElement("tbody", undefined, Belt_Array.mapWithIndex(standings, (function (index, standing) {
                        return React.createElement("tr", {
                                    key: String(index),
                                    className: "pagescores__row"
                                  }, React.createElement("th", {
                                        className: "pagescores__row-th pagescores__rank",
                                        scope: "col"
                                      }, index + 1 | 0), React.createElement("th", {
                                        className: "pagescores__row-th pagescores__playername",
                                        scope: "row"
                                      }, Data_Player$Coronate.fullName(Curry._1(getPlayer, standing.id))), Belt_Array.mapWithIndex(standings, (function (index2, opponent) {
                                          return React.createElement("td", {
                                                      key: String(index2),
                                                      className: "pagescores__row-td table__number"
                                                    }, getXScore(scoreData, standing.id, opponent.id));
                                        })), React.createElement("td", {
                                        className: "pagescores__row-td table__number"
                                      }, Curry._1(Data_Scoring$Coronate.Score.Sum.toNumeral, standing.score).format("1/2")), getRatingChangeTds(scoreData, standing.id));
                      }))));
}

var make = PageTourneyScores;

var Crosstable = {
  make: PageTourneyScores$Crosstable
};

export {
  make ,
  ScoreTable ,
  Crosstable ,
}
/* react Not a pure module */
