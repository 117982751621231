// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDom from "react-dom";
import * as Localforage from "localforage";
import * as Pages$Coronate from "./Pages.bs.js";
import * as Router$Coronate from "./Router.bs.js";
import * as Window$Coronate from "./Window.bs.js";
import * as PageOptions$Coronate from "./PageOptions.bs.js";
import * as PagePlayers$Coronate from "./PagePlayers.bs.js";
import * as PageTourney$Coronate from "./PageTournament/PageTourney.bs.js";
import * as LocalforageGetitems from "localforage-getitems";
import * as LocalforageSetitems from "localforage-setitems";
import * as LocalforageRemoveitems from "localforage-removeitems";
import * as PageTournamentList$Coronate from "./PageTournamentList.bs.js";

function App$App(Props) {
  var url = Router$Coronate.useUrl(undefined);
  return React.createElement(Window$Coronate.make, {
              children: (function (windowDispatch) {
                  var tmp;
                  if (typeof url === "number") {
                    switch (url) {
                      case /* Index */0 :
                          tmp = React.createElement(Window$Coronate.Body.make, {
                                children: React.createElement(Pages$Coronate.Splash.make, {}),
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* TournamentList */1 :
                          tmp = React.createElement(PageTournamentList$Coronate.make, {
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* PlayerList */2 :
                          tmp = React.createElement(PagePlayers$Coronate.make, {
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* TimeCalculator */3 :
                          tmp = React.createElement(Window$Coronate.Body.make, {
                                children: React.createElement(Pages$Coronate.TimeCalculator.make, {}),
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* Options */4 :
                          tmp = React.createElement(PageOptions$Coronate.make, {
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* NotFound */5 :
                          tmp = React.createElement(Window$Coronate.Body.make, {
                                children: React.createElement(Pages$Coronate.NotFound.make, {}),
                                windowDispatch: windowDispatch
                              });
                          break;
                      
                    }
                  } else {
                    tmp = url.TAG === /* Tournament */0 ? React.createElement(PageTourney$Coronate.make, {
                            tourneyId: url._0,
                            subPage: url._1,
                            windowDispatch: windowDispatch
                          }) : React.createElement(PagePlayers$Coronate.make, {
                            id: url._0,
                            windowDispatch: windowDispatch
                          });
                  }
                  return React.createElement("main", {
                              className: "app__main"
                            }, tmp);
                }),
              className: "app"
            });
}

var App = {
  make: App$App
};

function init(param) {
  var root = document.querySelector("#root");
  if (root == null) {
    console.error("Couldn't find root.");
  } else {
    ReactDom.render(React.createElement(App$App, {}), root);
  }
}

LocalforageGetitems.extendPrototype(Localforage);

LocalforageRemoveitems.extendPrototype(Localforage);

LocalforageSetitems.extendPrototype(Localforage);

export {
  App ,
  init ,
}
/*  Not a pure module */
