// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactFeather from "react-feather";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Router$Coronate from "../Router.bs.js";
import * as Window$Coronate from "../Window.bs.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as PageRound$Coronate from "./PageRound.bs.js";
import * as Data_Match$Coronate from "../Data/Data_Match.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as HtmlEntities$Coronate from "../HtmlEntities.bs.js";
import * as LoadTournament$Coronate from "./LoadTournament.bs.js";
import * as PageTourneySetup$Coronate from "./PageTourneySetup.bs.js";
import * as PageTourneyScores$Coronate from "./PageTourneyScores.bs.js";
import * as PageTourneyPlayers$Coronate from "./PageTourneyPlayers.bs.js";
import * as PageTournamentStatus$Coronate from "./PageTournamentStatus.bs.js";

function PageTourney$Footer(Props) {
  var tournament = Props.tournament;
  var match = tournament.isNewRoundReady ? (
      tournament.isItOver ? [
          HtmlEntities$Coronate.nbsp + " All rounds have completed.",
          /* Warning */1
        ] : [
          HtmlEntities$Coronate.nbsp + " Ready to begin a new round.",
          /* Success */0
        ]
    ) : [
      HtmlEntities$Coronate.nbsp + "Round in progress.",
      /* Generic */3
    ];
  var tooltipText = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "win__footer-block"
                }, "Rounds: ", Data_Rounds$Coronate.size(tournament.tourney.roundList), React.createElement("small", undefined, " out of "), tournament.roundCount), React.createElement("hr", {
                  className: "win__footer-divider"
                }), React.createElement("div", {
                  className: "win__footer-block"
                }, "Registered players: ", Belt_Map.size(tournament.activePlayers)), React.createElement("hr", {
                  className: "win__footer-divider"
                }), React.createElement(Utils$Coronate.$$Notification.make, {
                  children: tooltipText,
                  kind: match[1],
                  tooltip: tooltipText,
                  className: "win__footer-block",
                  style: {
                    backgroundColor: "transparent",
                    color: "initial",
                    display: "inline-flex",
                    margin: "0",
                    minHeight: "initial"
                  }
                }));
}

function noDraggy(e) {
  e.preventDefault();
}

function PageTourney$Sidebar(Props) {
  var tournament = Props.tournament;
  var windowDispatch = Props.windowDispatch;
  var tourney = tournament.tourney;
  var roundList = tourney.roundList;
  var setTourney = tournament.setTourney;
  var playersDispatch = tournament.playersDispatch;
  var players = tournament.players;
  var isItOver = tournament.isItOver;
  var activePlayers = tournament.activePlayers;
  var newRound = function ($$event) {
    $$event.preventDefault();
    var confirmText = "All rounds have completed. Are you sure you want to begin a new one?";
    var confirmed = isItOver && !window.confirm(confirmText) ? false : true;
    if (confirmed) {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: Data_Rounds$Coronate.addRound(roundList)
                });
    }
    
  };
  var delLastRound = function ($$event) {
    $$event.preventDefault();
    if (!window.confirm("Are you sure you want to delete the last round?")) {
      return ;
    }
    RescriptReactRouter.push("#/tourneys/" + Data_Id$Coronate.toString(tourney.id));
    var lastRoundId = Data_Rounds$Coronate.getLastKey(roundList);
    var round = Data_Rounds$Coronate.get(roundList, lastRoundId);
    if (round !== undefined) {
      Belt_Array.forEach(Data_Rounds$Coronate.Round.toArray(Caml_option.valFromOption(round)), (function (match_) {
              if (!(match_.result !== /* NotSet */6 && !Data_Match$Coronate.isBye(match_))) {
                return ;
              }
              var reset = function (id, rating) {
                var player = Belt_Map.get(players, id);
                if (player !== undefined) {
                  return Curry._1(playersDispatch, {
                              TAG: /* Set */1,
                              _0: player.id,
                              _1: Data_Player$Coronate.predMatchCount(Data_Player$Coronate.setRating(player, rating))
                            });
                }
                
              };
              reset(match_.whiteId, match_.whiteOrigRating);
              reset(match_.blackId, match_.blackOrigRating);
            }));
    }
    Curry._1(setTourney, {
          id: tourney.id,
          name: tourney.name,
          date: tourney.date,
          playerIds: tourney.playerIds,
          scoreAdjustments: tourney.scoreAdjustments,
          byeQueue: tourney.byeQueue,
          tieBreaks: tourney.tieBreaks,
          roundList: Data_Rounds$Coronate.delLastRound(roundList)
        });
    if (Data_Rounds$Coronate.size(roundList) === 0) {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: Data_Rounds$Coronate.addRound(roundList)
                });
    }
    
  };
  return React.createElement("div", undefined, React.createElement("nav", undefined, React.createElement("ul", {
                      style: {
                        marginTop: "0"
                      }
                    }, React.createElement("li", undefined, React.createElement(Router$Coronate.Link.make, {
                              children: null,
                              to_: /* TournamentList */1,
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.ChevronLeft, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Back")))), React.createElement("hr", undefined), React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement(Router$Coronate.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Setup */3
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.Settings, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Setup"))), React.createElement("li", undefined, React.createElement(Router$Coronate.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Players */0
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.Users, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Players"))), React.createElement("li", undefined, React.createElement(Router$Coronate.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Status */4
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.Activity, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Status"))), React.createElement("li", undefined, React.createElement(Router$Coronate.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Crosstable */2
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.Layers, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Crosstable"))), React.createElement("li", undefined, React.createElement(Router$Coronate.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Scores */1
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.List, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Score detail")))), React.createElement("hr", undefined), React.createElement("h5", {
                      className: "sidebar__hide-on-close sidebar__header"
                    }, "Rounds"), React.createElement("ul", {
                      className: "center-on-close"
                    }, Belt_Array.mapWithIndex(Data_Rounds$Coronate.toArray(roundList), (function (id, param) {
                            return React.createElement("li", {
                                        key: String(id)
                                      }, React.createElement(Router$Coronate.Link.make, {
                                            children: null,
                                            to_: {
                                              TAG: /* Tournament */0,
                                              _0: tourney.id,
                                              _1: /* Round */{
                                                _0: id
                                              }
                                            },
                                            onDragStart: noDraggy,
                                            onClick: (function (param) {
                                                Curry._1(windowDispatch, {
                                                      TAG: /* SetSidebar */1,
                                                      _0: false
                                                    });
                                              })
                                          }, id + 1 | 0, Data_Rounds$Coronate.isRoundComplete(roundList, activePlayers, id) ? React.createElement("span", {
                                                  className: "sidebar__hide-on-close caption-20"
                                                }, " Complete ", React.createElement(ReactFeather.Check, {})) : React.createElement("span", {
                                                  className: "sidebar__hide-on-close caption-20"
                                                }, " Not complete ", React.createElement(ReactFeather.AlertTriangle, {}))));
                          })))), React.createElement("hr", undefined), React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement("button", {
                          className: "sidebar-button",
                          style: {
                            width: "100%"
                          },
                          disabled: !tournament.isNewRoundReady,
                          onClick: newRound
                        }, React.createElement(ReactFeather.Plus, {}), React.createElement("span", {
                              className: "sidebar__hide-on-close"
                            }, " New round"))), React.createElement("li", {
                      style: {
                        textAlign: "center"
                      }
                    }, React.createElement("button", {
                          className: "button-micro sidebar-button",
                          style: {
                            marginTop: "8px"
                          },
                          disabled: Data_Rounds$Coronate.size(roundList) === 0,
                          onClick: delLastRound
                        }, React.createElement(ReactFeather.Trash2, {}), React.createElement("span", {
                              className: "sidebar__hide-on-close"
                            }, " Remove last round")))));
}

function PageTourney(Props) {
  var tourneyId = Props.tourneyId;
  var subPage = Props.subPage;
  var windowDispatch = Props.windowDispatch;
  return React.createElement(LoadTournament$Coronate.make, {
              children: (function (tournament) {
                  var tmp;
                  if (typeof subPage === "number") {
                    switch (subPage) {
                      case /* Players */0 :
                          tmp = React.createElement(PageTourneyPlayers$Coronate.make, {
                                tournament: tournament
                              });
                          break;
                      case /* Scores */1 :
                          tmp = React.createElement(PageTourneyScores$Coronate.make, {
                                tournament: tournament
                              });
                          break;
                      case /* Crosstable */2 :
                          tmp = React.createElement(PageTourneyScores$Coronate.Crosstable.make, {
                                tournament: tournament
                              });
                          break;
                      case /* Setup */3 :
                          tmp = React.createElement(PageTourneySetup$Coronate.make, {
                                tournament: tournament
                              });
                          break;
                      case /* Status */4 :
                          tmp = React.createElement(PageTournamentStatus$Coronate.make, {
                                tournament: tournament
                              });
                          break;
                      
                    }
                  } else {
                    tmp = React.createElement(PageRound$Coronate.make, {
                          roundId: subPage._0,
                          tournament: tournament
                        });
                  }
                  return React.createElement(Window$Coronate.Body.make, {
                              children: tmp,
                              windowDispatch: windowDispatch,
                              footerFunc: (function (param) {
                                  return React.createElement(PageTourney$Footer, {
                                              tournament: tournament
                                            });
                                }),
                              sidebarFunc: (function (param) {
                                  return React.createElement(PageTourney$Sidebar, {
                                              tournament: tournament,
                                              windowDispatch: param
                                            });
                                })
                            });
                }),
              tourneyId: tourneyId,
              windowDispatch: windowDispatch
            });
}

var make = PageTourney;

export {
  make ,
}
/* react Not a pure module */
