// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Numeral from "numeral";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Tabs from "@reach/tabs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Db$Coronate from "../Db.bs.js";
import * as ReactFeather from "react-feather";
import * as Hooks$Coronate from "../Hooks.bs.js";
import * as Pages$Coronate from "../Pages.bs.js";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as Externals$Coronate from "../Externals/Externals.bs.js";
import * as Data_Match$Coronate from "../Data/Data_Match.bs.js";
import * as PairPicker$Coronate from "./PairPicker.bs.js";
import * as Data_Config$Coronate from "../Data/Data_Config.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as Data_Ratings$Coronate from "../Data/Data_Ratings.bs.js";
import * as Data_Scoring$Coronate from "../Data/Data_Scoring.bs.js";
import VisuallyHidden from "@reach/visually-hidden";
import * as TournamentUtils$Coronate from "./TournamentUtils.bs.js";

function PageRound$PlayerMatchInfo(Props) {
  var player = Props.player;
  var origRating = Props.origRating;
  var newRating = Props.newRating;
  var getPlayer = Props.getPlayer;
  var scoreData = Props.scoreData;
  var players = Props.players;
  var config = Props.config;
  var match = TournamentUtils$Coronate.getScoreInfo(player, scoreData, config, getPlayer, players, origRating, newRating);
  var player$1 = match.player;
  return React.createElement("dl", {
              className: "player-card"
            }, React.createElement("h3", undefined, Data_Player$Coronate.fullName(player$1)), React.createElement("dt", undefined, "Score"), React.createElement("dd", undefined, match.score), React.createElement("dt", undefined, "Rating"), React.createElement(Utils$Coronate.TestId.make, {
                  children: React.createElement("dd", {
                        "aria-label": "Rating for " + Data_Player$Coronate.fullName(player$1)
                      }, match.rating),
                  testId: "rating-" + Data_Id$Coronate.toString(player$1.id)
                }), React.createElement("dt", undefined, "Color balance"), React.createElement("dd", undefined, match.colorBalance), React.createElement("dt", undefined, "Has had a bye round"), React.createElement("dd", undefined, match.hasBye ? "Yes" : "No"), React.createElement("dt", undefined, "Opponent history"), React.createElement("dd", undefined, React.createElement("ol", undefined, match.opponentResults)), React.createElement("dt", undefined, "Players to avoid:"), React.createElement("dd", undefined, React.createElement("ul", undefined, match.avoidListHtml)));
}

function PageRound$MatchRow(Props) {
  var isCompactOpt = Props.isCompact;
  var pos = Props.pos;
  var m = Props.m;
  var roundId = Props.roundId;
  var selectedMatch = Props.selectedMatch;
  var setSelectedMatch = Props.setSelectedMatch;
  var scoreData = Props.scoreData;
  var tournament = Props.tournament;
  var classNameOpt = Props.className;
  var config = Props.config;
  var isCompact = isCompactOpt !== undefined ? isCompactOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tourney = tournament.tourney;
  var roundList = tourney.roundList;
  var setTourney = tournament.setTourney;
  var playersDispatch = tournament.playersDispatch;
  var players = tournament.players;
  var getPlayer = tournament.getPlayer;
  var dialog = Hooks$Coronate.useBool(false);
  var whitePlayer = Curry._1(getPlayer, m.whiteId);
  var blackPlayer = Curry._1(getPlayer, m.blackId);
  var resultDisplay = function (playerColor) {
    var won = React.createElement(ReactFeather.Award, {
          className: "pageround__wonicon"
        });
    var lost = React.createElement(VisuallyHidden, {
          children: "Lost"
        });
    var aborted = React.createElement("span", {
          "aria-label": "Draw",
          role: "img",
          style: {
            filter: "grayscale(60%)"
          }
        }, "❌");
    var match = m.result;
    switch (match) {
      case /* WhiteWon */0 :
          if (playerColor) {
            return lost;
          } else {
            return won;
          }
      case /* BlackWon */1 :
          if (playerColor) {
            return won;
          } else {
            return lost;
          }
      case /* Draw */2 :
          return React.createElement("span", {
                      "aria-label": "Draw",
                      role: "img",
                      style: {
                        filter: "grayscale(70%)"
                      }
                    }, "🤝");
      case /* Aborted */3 :
          return aborted;
      case /* WhiteAborted */4 :
          if (playerColor) {
            return won;
          } else {
            return aborted;
          }
      case /* BlackAborted */5 :
          if (playerColor) {
            return aborted;
          } else {
            return won;
          }
      case /* NotSet */6 :
          return React.createElement(VisuallyHidden, {
                      children: "Not set"
                    });
      
    }
  };
  var setMatchResult = function (jsResultCode) {
    var newResult = Data_Match$Coronate.Result.fromString(jsResultCode);
    if (m.result === newResult) {
      return ;
    }
    var blackId = m.blackId;
    var whiteId = m.whiteId;
    var whiteOpt = Belt_Map.get(players, whiteId);
    var blackOpt = Belt_Map.get(players, blackId);
    var match;
    var exit = 0;
    if (whiteOpt !== undefined && blackOpt !== undefined && newResult < 3) {
      match = Data_Ratings$Coronate.calcNewRatings(m.whiteOrigRating, m.blackOrigRating, whiteOpt.matchCount, blackOpt.matchCount, newResult);
    } else {
      exit = 1;
    }
    if (exit === 1) {
      match = [
        m.whiteOrigRating,
        m.blackOrigRating
      ];
    }
    var blackNewRating = match[1];
    var whiteNewRating = match[0];
    var whiteOpt$1 = Belt_Option.map(whiteOpt, (function (white) {
            return Data_Player$Coronate.setRating(white, whiteNewRating);
          }));
    var blackOpt$1 = Belt_Option.map(blackOpt, (function (black) {
            return Data_Player$Coronate.setRating(black, blackNewRating);
          }));
    if (!Data_Match$Coronate.isBye(m)) {
      var match$1 = m.result;
      if (match$1 >= 6) {
        Belt_Option.forEach(whiteOpt$1, (function (white) {
                Curry._1(playersDispatch, {
                      TAG: /* Set */1,
                      _0: whiteId,
                      _1: Data_Player$Coronate.succMatchCount(white)
                    });
              }));
        Belt_Option.forEach(blackOpt$1, (function (black) {
                Curry._1(playersDispatch, {
                      TAG: /* Set */1,
                      _0: blackId,
                      _1: Data_Player$Coronate.succMatchCount(black)
                    });
              }));
      } else if (newResult === /* NotSet */6) {
        Belt_Option.forEach(whiteOpt$1, (function (white) {
                Curry._1(playersDispatch, {
                      TAG: /* Set */1,
                      _0: whiteId,
                      _1: Data_Player$Coronate.predMatchCount(white)
                    });
              }));
        Belt_Option.forEach(blackOpt$1, (function (black) {
                Curry._1(playersDispatch, {
                      TAG: /* Set */1,
                      _0: blackId,
                      _1: Data_Player$Coronate.predMatchCount(black)
                    });
              }));
      } else {
        Belt_Option.forEach(whiteOpt$1, (function (white) {
                Curry._1(playersDispatch, {
                      TAG: /* Set */1,
                      _0: whiteId,
                      _1: white
                    });
              }));
        Belt_Option.forEach(blackOpt$1, (function (black) {
                Curry._1(playersDispatch, {
                      TAG: /* Set */1,
                      _0: blackId,
                      _1: black
                    });
              }));
      }
    }
    var newMatch_id = m.id;
    var newMatch_whiteId = m.whiteId;
    var newMatch_blackId = m.blackId;
    var newMatch_whiteOrigRating = m.whiteOrigRating;
    var newMatch_blackOrigRating = m.blackOrigRating;
    var newMatch = {
      id: newMatch_id,
      whiteId: newMatch_whiteId,
      blackId: newMatch_blackId,
      whiteNewRating: whiteNewRating,
      blackNewRating: blackNewRating,
      whiteOrigRating: newMatch_whiteOrigRating,
      blackOrigRating: newMatch_blackOrigRating,
      result: newResult
    };
    Belt_Option.map(Data_Rounds$Coronate.setMatch(roundList, roundId, newMatch), (function (roundList) {
            Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: roundList
                });
          }));
  };
  var setMatchResultBlur = function ($$event) {
    setMatchResult($$event.target.value);
  };
  var setMatchResultChange = function ($$event) {
    setMatchResult($$event.target.value);
  };
  var tmp;
  if (isCompact || setSelectedMatch === undefined) {
    tmp = null;
  } else {
    var tmp$1;
    if (scoreData !== undefined) {
      var scoreData$1 = Caml_option.valFromOption(scoreData);
      tmp$1 = React.createElement(Externals$Coronate.Dialog.make, {
            isOpen: dialog.state,
            onDismiss: (function (param) {
                Curry._1(dialog.setFalse, undefined);
              }),
            ariaLabel: "Match information",
            children: null,
            className: ""
          }, React.createElement("button", {
                className: "button-micro button-primary",
                onClick: (function (param) {
                    Curry._1(dialog.setFalse, undefined);
                  })
              }, "close"), React.createElement("p", undefined, tourney.name), React.createElement("p", undefined, "Round " + String(roundId + 1 | 0) + " match " + String(pos + 1 | 0) + ""), React.createElement(Utils$Coronate.PanelContainer.make, {
                children: null
              }, React.createElement(Utils$Coronate.Panel.make, {
                    children: React.createElement(PageRound$PlayerMatchInfo, {
                          player: whitePlayer,
                          origRating: m.whiteOrigRating,
                          newRating: m.whiteNewRating,
                          getPlayer: getPlayer,
                          scoreData: scoreData$1,
                          players: players,
                          config: config
                        })
                  }), React.createElement(Utils$Coronate.Panel.make, {
                    children: React.createElement(PageRound$PlayerMatchInfo, {
                          player: blackPlayer,
                          origRating: m.blackOrigRating,
                          newRating: m.blackNewRating,
                          getPlayer: getPlayer,
                          scoreData: scoreData$1,
                          players: players,
                          config: config
                        })
                  })));
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement("td", {
          className: "pageround__controls data__input"
        }, Belt_Option.mapWithDefault(selectedMatch, true, (function (id) {
                return !Data_Id$Coronate.eq(id, m.id);
              })) ? React.createElement("button", {
                className: "button-ghost",
                title: "Edit match",
                onClick: (function (param) {
                    Curry._1(setSelectedMatch, (function (param) {
                            return Caml_option.some(m.id);
                          }));
                  })
              }, React.createElement(ReactFeather.Circle, {}), React.createElement(VisuallyHidden, {
                    children: "Edit match for " + Data_Player$Coronate.fullName(whitePlayer) + " versus " + Data_Player$Coronate.fullName(blackPlayer) + ""
                  })) : React.createElement("button", {
                className: "button-ghost button-pressed",
                title: "End editing match",
                onClick: (function (param) {
                    Curry._1(setSelectedMatch, (function (param) {
                            
                          }));
                  })
              }, React.createElement(ReactFeather.CheckCircle, {})), React.createElement("button", {
              className: "button-ghost",
              title: "Open match information.",
              onClick: (function (param) {
                  Curry._1(dialog.setTrue, undefined);
                })
            }, React.createElement(ReactFeather.Info, {}), React.createElement(VisuallyHidden, {
                  children: "View information for match: " + Data_Player$Coronate.fullName(whitePlayer) + " versus " + Data_Player$Coronate.fullName(blackPlayer) + ""
                })), tmp$1);
  }
  return React.createElement("tr", {
              className: Cn.append(className, Cn.mapSome(selectedMatch, (function (id) {
                          if (Data_Id$Coronate.eq(m.id, id)) {
                            return "selected";
                          } else {
                            return "";
                          }
                        })))
            }, React.createElement("th", {
                  className: "pageround__row-id table__number",
                  scope: "row"
                }, String(pos + 1 | 0)), React.createElement("td", {
                  className: "pageround__playerresult"
                }, resultDisplay(/* White */0)), React.createElement(Utils$Coronate.TestId.make, {
                  children: React.createElement("td", {
                        className: "table__player row__player " + Data_Player$Coronate.Type.toString(whitePlayer.type_),
                        id: "match-" + (String(pos) + "-white")
                      }, Data_Player$Coronate.fullName(whitePlayer)),
                  testId: "match-" + (String(pos) + "-white")
                }), React.createElement("td", {
                  className: "pageround__playerresult"
                }, resultDisplay(/* Black */1)), React.createElement(Utils$Coronate.TestId.make, {
                  children: React.createElement("td", {
                        className: "table__player row__player " + Data_Player$Coronate.Type.toString(blackPlayer.type_),
                        id: "match-" + (String(pos) + "-black")
                      }, Data_Player$Coronate.fullName(blackPlayer)),
                  testId: "match-" + (String(pos) + "-black")
                }), React.createElement("td", {
                  className: "pageround__matchresult__score monospace"
                }, Numeral(Data_Scoring$Coronate.Score.toFloat(Data_Scoring$Coronate.Score.fromResultWhite(m.result))).format("1/2"), "-", Numeral(Data_Scoring$Coronate.Score.toFloat(Data_Scoring$Coronate.Score.fromResultBlack(m.result))).format("1/2")), React.createElement("td", {
                  className: "pageround__matchresult data__input row__controls"
                }, React.createElement(Utils$Coronate.TestId.make, {
                      children: React.createElement("select", {
                            className: "pageround__winnerSelect",
                            value: Data_Match$Coronate.Result.toString(m.result),
                            onBlur: setMatchResultBlur,
                            onChange: setMatchResultChange
                          }, React.createElement("option", {
                                value: Data_Match$Coronate.Result.toString(/* NotSet */6)
                              }, "Select winner"), React.createElement("option", {
                                value: Data_Match$Coronate.Result.toString(/* WhiteWon */0)
                              }, Data_Config$Coronate.aliasToStringWhite(config), " won"), React.createElement("option", {
                                value: Data_Match$Coronate.Result.toString(/* BlackWon */1)
                              }, Data_Config$Coronate.aliasToStringBlack(config), " won"), React.createElement("option", {
                                value: Data_Match$Coronate.Result.toString(/* Draw */2)
                              }, "Draw"), React.createElement("option", {
                                value: Data_Match$Coronate.Result.toString(/* Aborted */3)
                              }, "Aborted"), React.createElement("option", {
                                value: Data_Match$Coronate.Result.toString(/* WhiteAborted */4)
                              }, Data_Config$Coronate.aliasToStringWhite(config), " Aborted"), React.createElement("option", {
                                value: Data_Match$Coronate.Result.toString(/* BlackAborted */5)
                              }, Data_Config$Coronate.aliasToStringBlack(config), " Aborted")),
                      testId: "match-" + String(pos) + "-select"
                    })), tmp);
}

function PageRound$RoundTable(Props) {
  var isCompactOpt = Props.isCompact;
  var roundId = Props.roundId;
  var matches = Props.matches;
  var selectedMatch = Props.selectedMatch;
  var setSelectedMatch = Props.setSelectedMatch;
  var tournament = Props.tournament;
  var scoreData = Props.scoreData;
  var isCompact = isCompactOpt !== undefined ? isCompactOpt : false;
  var match = Db$Coronate.useConfig(undefined);
  var config = match[0];
  return React.createElement("table", {
              className: "pageround__table"
            }, matches.length === 0 ? null : React.createElement(React.Fragment, undefined, React.createElement("caption", {
                        className: isCompact ? "title-30" : "title-40"
                      }, "Round ", roundId + 1 | 0), React.createElement("thead", {
                        className: "pageround__table-head"
                      }, React.createElement("tr", undefined, React.createElement("th", {
                                className: "pageround__row-id",
                                scope: "col"
                              }, "#"), React.createElement("th", {
                                scope: "col"
                              }, React.createElement(VisuallyHidden, {
                                    children: null
                                  }, Data_Config$Coronate.aliasToStringWhite(config), " result")), React.createElement("th", {
                                className: "row__player",
                                scope: "col"
                              }, Data_Config$Coronate.aliasToStringWhite(config)), React.createElement("th", {
                                scope: "col"
                              }, React.createElement(VisuallyHidden, {
                                    children: null
                                  }, Data_Config$Coronate.aliasToStringBlack(config), " result")), React.createElement("th", {
                                className: "row__player",
                                scope: "col"
                              }, Data_Config$Coronate.aliasToStringBlack(config)), React.createElement("th", {
                                className: "row__result",
                                colSpan: 2,
                                scope: "col"
                              }, "Match result"), isCompact ? null : React.createElement("th", {
                                  className: "row__controls",
                                  scope: "col"
                                }, React.createElement(VisuallyHidden, {
                                      children: "Controls"
                                    }))))), React.createElement("tbody", {
                  className: "content"
                }, Belt_Array.mapWithIndex(matches, (function (pos, m) {
                        var tmp = {
                          isCompact: isCompact,
                          pos: pos,
                          m: m,
                          roundId: roundId,
                          setSelectedMatch: setSelectedMatch,
                          scoreData: scoreData,
                          tournament: tournament,
                          className: "pageround__tr",
                          config: config,
                          key: Data_Id$Coronate.toString(m.id)
                        };
                        if (selectedMatch !== undefined) {
                          tmp.selectedMatch = Caml_option.valFromOption(selectedMatch);
                        }
                        return React.createElement(PageRound$MatchRow, tmp);
                      }))));
}

var RoundTable = {
  make: PageRound$RoundTable
};

function PageRound$Round(Props) {
  var roundId = Props.roundId;
  var tournament = Props.tournament;
  var scoreData = Props.scoreData;
  var tourney = tournament.tourney;
  var roundList = tourney.roundList;
  var setTourney = tournament.setTourney;
  var playersDispatch = tournament.playersDispatch;
  var players = tournament.players;
  var match = React.useState(function () {
        
      });
  var setSelectedMatch = match[1];
  var selectedMatch = match[0];
  var moveMatch = function (matchId, direction, round) {
    var newRound = Data_Rounds$Coronate.Round.moveMatch(round, matchId, direction);
    if (newRound === undefined) {
      return ;
    }
    var roundList$1 = Data_Rounds$Coronate.set(roundList, roundId, Caml_option.valFromOption(newRound));
    if (roundList$1 !== undefined) {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: Caml_option.valFromOption(roundList$1)
                });
    }
    
  };
  var matches = Data_Rounds$Coronate.get(tourney.roundList, roundId);
  if (matches === undefined) {
    return React.createElement(Pages$Coronate.NotFound.make, {});
  }
  var matches$1 = Caml_option.valFromOption(matches);
  var tmp = {
    roundId: roundId,
    matches: Data_Rounds$Coronate.Round.toArray(matches$1),
    setSelectedMatch: setSelectedMatch,
    tournament: tournament,
    scoreData: scoreData
  };
  if (selectedMatch !== undefined) {
    tmp.selectedMatch = Caml_option.valFromOption(selectedMatch);
  }
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement("div", {
                  className: "toolbar"
                }, React.createElement("button", {
                      className: "button-micro",
                      disabled: selectedMatch === undefined,
                      onClick: (function (param) {
                          Belt_Option.map(selectedMatch, (function (__x) {
                                  var match = Data_Rounds$Coronate.Round.getMatchById(matches$1, __x);
                                  if (match !== undefined && match.result !== /* NotSet */6 && !Data_Match$Coronate.isBye(match)) {
                                    var reset = function (id, rating) {
                                      var player = Belt_Map.get(players, id);
                                      if (player !== undefined) {
                                        return Curry._1(playersDispatch, {
                                                    TAG: /* Set */1,
                                                    _0: player.id,
                                                    _1: Data_Player$Coronate.predMatchCount(Data_Player$Coronate.setRating(player, rating))
                                                  });
                                      }
                                      
                                    };
                                    reset(match.whiteId, match.whiteOrigRating);
                                    reset(match.blackId, match.blackOrigRating);
                                  }
                                  var newRound = Data_Rounds$Coronate.Round.removeMatchById(matches$1, __x);
                                  var roundList$1 = Data_Rounds$Coronate.set(roundList, roundId, newRound);
                                  if (roundList$1 !== undefined) {
                                    Curry._1(setTourney, {
                                          id: tourney.id,
                                          name: tourney.name,
                                          date: tourney.date,
                                          playerIds: tourney.playerIds,
                                          scoreAdjustments: tourney.scoreAdjustments,
                                          byeQueue: tourney.byeQueue,
                                          tieBreaks: tourney.tieBreaks,
                                          roundList: Caml_option.valFromOption(roundList$1)
                                        });
                                  }
                                  Curry._1(setSelectedMatch, (function (param) {
                                          
                                        }));
                                }));
                        })
                    }, React.createElement(ReactFeather.Trash2, {}), " Unmatch"), " ", React.createElement("button", {
                      className: "button-micro",
                      disabled: selectedMatch === undefined,
                      onClick: (function (param) {
                          Belt_Option.map(selectedMatch, (function (__x) {
                                  var match_ = Data_Rounds$Coronate.Round.getMatchById(matches$1, __x);
                                  if (match_ === undefined) {
                                    return ;
                                  }
                                  var newMatch = Data_Match$Coronate.swapColors(match_);
                                  Belt_Option.map(Data_Rounds$Coronate.setMatch(roundList, roundId, newMatch), (function (roundList) {
                                          Curry._1(setTourney, {
                                                id: tourney.id,
                                                name: tourney.name,
                                                date: tourney.date,
                                                playerIds: tourney.playerIds,
                                                scoreAdjustments: tourney.scoreAdjustments,
                                                byeQueue: tourney.byeQueue,
                                                tieBreaks: tourney.tieBreaks,
                                                roundList: roundList
                                              });
                                        }));
                                }));
                        })
                    }, React.createElement(ReactFeather.Repeat, {}), " Swap colors"), " ", React.createElement("button", {
                      className: "button-micro",
                      disabled: selectedMatch === undefined,
                      onClick: (function (param) {
                          Belt_Option.map(selectedMatch, (function (__x) {
                                  moveMatch(__x, -1, matches$1);
                                }));
                        })
                    }, React.createElement(ReactFeather.ArrowUp, {}), " Move up"), " ", React.createElement("button", {
                      className: "button-micro",
                      disabled: selectedMatch === undefined,
                      onClick: (function (param) {
                          Belt_Option.map(selectedMatch, (function (__x) {
                                  moveMatch(__x, 1, matches$1);
                                }));
                        })
                    }, React.createElement(ReactFeather.ArrowDown, {}), " Move down")), Data_Rounds$Coronate.Round.size(matches$1) === 0 ? React.createElement("p", undefined, "No players matched yet.") : null, React.createElement(PageRound$RoundTable, tmp));
}

function PageRound(Props) {
  var roundId = Props.roundId;
  var tournament = Props.tournament;
  var match = TournamentUtils$Coronate.useRoundData(roundId, tournament);
  var unmatched = match.unmatched;
  var scoreData = match.scoreData;
  var unmatchedCount = Belt_Map.size(unmatched);
  var activePlayersCount = Belt_Map.size(tournament.activePlayers);
  var initialTab = unmatchedCount === activePlayersCount ? 1 : 0;
  var match$1 = React.useState(function () {
        return initialTab;
      });
  var setOpenTab = match$1[1];
  React.useEffect((function () {
          if (unmatchedCount === activePlayersCount) {
            Curry._1(setOpenTab, (function (param) {
                    return 1;
                  }));
          }
          if (unmatchedCount === 0) {
            Curry._1(setOpenTab, (function (param) {
                    return 0;
                  }));
          }
          
        }), [
        unmatchedCount,
        activePlayersCount,
        setOpenTab
      ]);
  return React.createElement(Tabs.Tabs, {
              index: match$1[0],
              onChange: (function (index) {
                  Curry._1(setOpenTab, (function (param) {
                          return index;
                        }));
                }),
              children: null
            }, React.createElement(Tabs.TabList, {
                  children: null
                }, React.createElement(Tabs.Tab, {
                      disabled: unmatchedCount === activePlayersCount,
                      children: null
                    }, React.createElement(ReactFeather.List, {}), " Matches"), React.createElement(Tabs.Tab, {
                      disabled: unmatchedCount === 0,
                      children: null
                    }, React.createElement(ReactFeather.Users, {}), " Unmatched players (" + String(unmatchedCount) + ")")), React.createElement(Tabs.TabPanels, {
                  children: null
                }, React.createElement(Tabs.TabPanel, {
                      children: React.createElement(PageRound$Round, {
                            roundId: roundId,
                            tournament: tournament,
                            scoreData: scoreData
                          })
                    }), React.createElement(Tabs.TabPanel, {
                      children: React.createElement("div", undefined, unmatchedCount !== 0 ? React.createElement(PairPicker$Coronate.make, {
                                  roundId: roundId,
                                  tournament: tournament,
                                  scoreData: scoreData,
                                  unmatched: unmatched,
                                  unmatchedWithDummy: match.unmatchedWithDummy
                                }) : null)
                    })));
}

var make = PageRound;

export {
  RoundTable ,
  make ,
}
/* react Not a pure module */
