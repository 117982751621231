// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Numeral from "numeral";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Db$Coronate from "./Db.bs.js";
import * as ReactFeather from "react-feather";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Hooks$Coronate from "./Hooks.bs.js";
import * as Utils$Coronate from "./Utils.bs.js";
import * as Router$Coronate from "./Router.bs.js";
import * as Window$Coronate from "./Window.bs.js";
import * as Data_Id$Coronate from "./Data/Data_Id.bs.js";
import * as Externals$Coronate from "./Externals/Externals.bs.js";
import * as Data_Player$Coronate from "./Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "./Data/Data_Rounds.bs.js";
import * as Data_Ratings$Coronate from "./Data/Data_Ratings.bs.js";
import VisuallyHidden from "@reach/visually-hidden";

var sortFirstName = {
  TAG: /* GetString */0,
  _0: (function (x) {
      return x.firstName;
    })
};

var sortLastName = {
  TAG: /* GetString */0,
  _0: (function (x) {
      return x.lastName;
    })
};

var sortRating = {
  TAG: /* GetInt */1,
  _0: (function (x) {
      return x.rating;
    })
};

var sortMatchCount = {
  TAG: /* GetInt */1,
  _0: (function (x) {
      return Data_Player$Coronate.NatInt.toInt(x.matchCount);
    })
};

function firstName(firstName$1) {
  if (firstName$1 === "") {
    return {
            TAG: /* Error */1,
            _0: "First name is required"
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: firstName$1
          };
  }
}

function lastName(lastName$1) {
  if (lastName$1 === "") {
    return {
            TAG: /* Error */1,
            _0: "Last name is required"
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: lastName$1
          };
  }
}

function rating(rating$1) {
  var rating$2 = Belt_Int.fromString(rating$1);
  if (rating$2 !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: rating$2
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Rating must be a number"
          };
  }
}

function matchCount(matchCount$1) {
  var i = Belt_Int.fromString(matchCount$1);
  if (i !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Data_Player$Coronate.NatInt.fromInt(i)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Match count must be a number"
          };
  }
}

var initial = {
  matchCount: /* Pristine */0,
  rating: /* Pristine */0,
  lastName: /* Pristine */0,
  firstName: /* Pristine */0
};

function validateForm(param) {
  var fieldStatuses = param.fieldStatuses;
  var input = param.input;
  var result = fieldStatuses.matchCount;
  var matchCountResult = result ? result._0 : matchCount(input.matchCount);
  var result$1 = fieldStatuses.rating;
  var ratingResult = result$1 ? result$1._0 : rating(input.rating);
  var result$2 = fieldStatuses.lastName;
  var lastNameResult = result$2 ? result$2._0 : lastName(input.lastName);
  var result$3 = fieldStatuses.firstName;
  var firstNameResult = result$3 ? result$3._0 : firstName(input.firstName);
  if (matchCountResult.TAG === /* Ok */0 && ratingResult.TAG === /* Ok */0 && lastNameResult.TAG === /* Ok */0 && firstNameResult.TAG === /* Ok */0) {
    return {
            TAG: /* Valid */0,
            output: {
              firstName: firstNameResult._0,
              lastName: lastNameResult._0,
              rating: ratingResult._0,
              matchCount: matchCountResult._0
            },
            fieldStatuses: {
              matchCount: /* Dirty */{
                _0: matchCountResult
              },
              rating: /* Dirty */{
                _0: ratingResult
              },
              lastName: /* Dirty */{
                _0: lastNameResult
              },
              firstName: /* Dirty */{
                _0: firstNameResult
              }
            }
          };
  }
  return {
          TAG: /* Invalid */1,
          fieldStatuses: {
            matchCount: /* Dirty */{
              _0: matchCountResult
            },
            rating: /* Dirty */{
              _0: ratingResult
            },
            lastName: /* Dirty */{
              _0: lastNameResult
            },
            firstName: /* Dirty */{
              _0: firstNameResult
            }
          }
        };
}

function useForm(initialInput) {
  var memoizedInitialState = React.useMemo((function () {
          return {
                  input: initialInput,
                  fieldStatuses: initial,
                  formStatus: /* Editing */0
                };
        }), [initialInput]);
  var match = React.useReducer((function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurMatchCountField */0 :
                  var result = Utils$Coronate.FormHelper.validateFieldOnBlurWithValidator(state.input.matchCount, state.fieldStatuses.matchCount, matchCount);
                  if (result === undefined) {
                    return state;
                  }
                  var init = state.fieldStatuses;
                  return {
                          input: state.input,
                          fieldStatuses: {
                            matchCount: result,
                            rating: init.rating,
                            lastName: init.lastName,
                            firstName: init.firstName
                          },
                          formStatus: state.formStatus
                        };
              case /* BlurRatingField */1 :
                  var result$1 = Utils$Coronate.FormHelper.validateFieldOnBlurWithValidator(state.input.rating, state.fieldStatuses.rating, rating);
                  if (result$1 === undefined) {
                    return state;
                  }
                  var init$1 = state.fieldStatuses;
                  return {
                          input: state.input,
                          fieldStatuses: {
                            matchCount: init$1.matchCount,
                            rating: result$1,
                            lastName: init$1.lastName,
                            firstName: init$1.firstName
                          },
                          formStatus: state.formStatus
                        };
              case /* BlurLastNameField */2 :
                  var result$2 = Utils$Coronate.FormHelper.validateFieldOnBlurWithValidator(state.input.lastName, state.fieldStatuses.lastName, lastName);
                  if (result$2 === undefined) {
                    return state;
                  }
                  var init$2 = state.fieldStatuses;
                  return {
                          input: state.input,
                          fieldStatuses: {
                            matchCount: init$2.matchCount,
                            rating: init$2.rating,
                            lastName: result$2,
                            firstName: init$2.firstName
                          },
                          formStatus: state.formStatus
                        };
              case /* BlurFirstNameField */3 :
                  var result$3 = Utils$Coronate.FormHelper.validateFieldOnBlurWithValidator(state.input.firstName, state.fieldStatuses.firstName, firstName);
                  if (result$3 === undefined) {
                    return state;
                  }
                  var init$3 = state.fieldStatuses;
                  return {
                          input: state.input,
                          fieldStatuses: {
                            matchCount: init$3.matchCount,
                            rating: init$3.rating,
                            lastName: init$3.lastName,
                            firstName: result$3
                          },
                          formStatus: state.formStatus
                        };
              case /* Reset */4 :
                  return {
                          input: initialInput,
                          fieldStatuses: initial,
                          formStatus: /* Editing */0
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateMatchCountField */0 :
                  var nextValue = action._0;
                  var init$4 = state.input;
                  var init$5 = state.fieldStatuses;
                  return {
                          input: {
                            firstName: init$4.firstName,
                            lastName: init$4.lastName,
                            rating: init$4.rating,
                            matchCount: nextValue
                          },
                          fieldStatuses: {
                            matchCount: /* Dirty */{
                              _0: matchCount(nextValue)
                            },
                            rating: init$5.rating,
                            lastName: init$5.lastName,
                            firstName: init$5.firstName
                          },
                          formStatus: state.formStatus
                        };
              case /* UpdateRatingField */1 :
                  var nextValue$1 = action._0;
                  var init$6 = state.input;
                  var init$7 = state.fieldStatuses;
                  return {
                          input: {
                            firstName: init$6.firstName,
                            lastName: init$6.lastName,
                            rating: nextValue$1,
                            matchCount: init$6.matchCount
                          },
                          fieldStatuses: {
                            matchCount: init$7.matchCount,
                            rating: /* Dirty */{
                              _0: rating(nextValue$1)
                            },
                            lastName: init$7.lastName,
                            firstName: init$7.firstName
                          },
                          formStatus: state.formStatus
                        };
              case /* UpdateLastNameField */2 :
                  var nextValue$2 = action._0;
                  var init$8 = state.input;
                  var init$9 = state.fieldStatuses;
                  return {
                          input: {
                            firstName: init$8.firstName,
                            lastName: nextValue$2,
                            rating: init$8.rating,
                            matchCount: init$8.matchCount
                          },
                          fieldStatuses: {
                            matchCount: init$9.matchCount,
                            rating: init$9.rating,
                            lastName: /* Dirty */{
                              _0: lastName(nextValue$2)
                            },
                            firstName: init$9.firstName
                          },
                          formStatus: state.formStatus
                        };
              case /* UpdateFirstNameField */3 :
                  var nextValue$3 = action._0;
                  var init$10 = state.input;
                  var init$11 = state.fieldStatuses;
                  return {
                          input: {
                            firstName: nextValue$3,
                            lastName: init$10.lastName,
                            rating: init$10.rating,
                            matchCount: init$10.matchCount
                          },
                          fieldStatuses: {
                            matchCount: init$11.matchCount,
                            rating: init$11.rating,
                            lastName: init$11.lastName,
                            firstName: /* Dirty */{
                              _0: firstName(nextValue$3)
                            }
                          },
                          formStatus: state.formStatus
                        };
              case /* Submit */4 :
                  var match = state.formStatus;
                  if (match) {
                    return state;
                  }
                  var match$1 = validateForm(state);
                  if (match$1.TAG === /* Valid */0) {
                    return {
                            input: state.input,
                            fieldStatuses: match$1.fieldStatuses,
                            formStatus: /* Submitting */{
                              _0: match$1.output,
                              _1: action._0
                            }
                          };
                  } else {
                    return {
                            input: state.input,
                            fieldStatuses: match$1.fieldStatuses,
                            formStatus: /* Editing */0
                          };
                  }
              
            }
          }
        }), memoizedInitialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var match = state.formStatus;
          if (match) {
            Curry._1(match._1, match._0);
            Curry._1(dispatch, /* Reset */4);
          }
          
        }), [state.formStatus]);
  return {
          state: state,
          dispatch: dispatch
        };
}

function updateRating(param, nextValue) {
  Curry._1(param.dispatch, {
        TAG: /* UpdateRatingField */1,
        _0: nextValue
      });
}

function updateLastName(param, nextValue) {
  Curry._1(param.dispatch, {
        TAG: /* UpdateLastNameField */2,
        _0: nextValue
      });
}

function updateFirstName(param, nextValue) {
  Curry._1(param.dispatch, {
        TAG: /* UpdateFirstNameField */3,
        _0: nextValue
      });
}

function blurRating(param) {
  Curry._1(param.dispatch, /* BlurRatingField */1);
}

function blurLastName(param) {
  Curry._1(param.dispatch, /* BlurLastNameField */2);
}

function blurFirstName(param) {
  Curry._1(param.dispatch, /* BlurFirstNameField */3);
}

function matchCountResult(param) {
  return Utils$Coronate.FormHelper.exposeFieldResult(param.state.fieldStatuses.matchCount);
}

function ratingResult(param) {
  return Utils$Coronate.FormHelper.exposeFieldResult(param.state.fieldStatuses.rating);
}

function lastNameResult(param) {
  return Utils$Coronate.FormHelper.exposeFieldResult(param.state.fieldStatuses.lastName);
}

function firstNameResult(param) {
  return Utils$Coronate.FormHelper.exposeFieldResult(param.state.fieldStatuses.firstName);
}

function input(param) {
  return param.state.input;
}

function dirty(param) {
  var match = param.state.fieldStatuses;
  if (match.matchCount || match.rating || match.lastName || match.firstName) {
    return true;
  } else {
    return false;
  }
}

function valid(param) {
  var match = validateForm(param.state);
  if (match.TAG === /* Valid */0) {
    return true;
  } else {
    return false;
  }
}

function submitting(param) {
  var match = param.state.formStatus;
  if (match) {
    return true;
  } else {
    return false;
  }
}

function submit(param, fn) {
  Curry._1(param.dispatch, {
        TAG: /* Submit */4,
        _0: fn
      });
}

function errorNotification(x) {
  if (x !== undefined && x.TAG !== /* Ok */0) {
    return React.createElement(Utils$Coronate.$$Notification.make, {
                children: x._0,
                kind: /* Error */2
              });
  } else {
    return null;
  }
}

function PagePlayers$NewPlayerForm(Props) {
  var dispatch = Props.dispatch;
  var addPlayerCallback = Props.addPlayerCallback;
  var form = useForm({
        firstName: "",
        lastName: "",
        rating: "1200",
        matchCount: "0"
      });
  var input$1 = input(form);
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  submit(form, (function (param) {
                          var id = Data_Id$Coronate.random(undefined);
                          Curry._1(dispatch, {
                                TAG: /* Set */1,
                                _0: id,
                                _1: {
                                  firstName: param.firstName,
                                  id: id,
                                  lastName: param.lastName,
                                  matchCount: param.matchCount,
                                  rating: param.rating,
                                  type_: /* Person */0
                                }
                              });
                          if (addPlayerCallback !== undefined) {
                            return Curry._1(addPlayerCallback, id);
                          }
                          
                        }));
                })
            }, React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Register a new player"), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "firstName"
                        }, "First name"), React.createElement("input", {
                          name: "firstName",
                          required: true,
                          type: "text",
                          value: input$1.firstName,
                          onBlur: (function (param) {
                              blurFirstName(form);
                            }),
                          onChange: (function ($$event) {
                              updateFirstName(form, $$event.target.value);
                            })
                        })), errorNotification(firstNameResult(form)), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "lastName"
                        }, "Last name"), React.createElement("input", {
                          name: "lastName",
                          required: true,
                          type: "text",
                          value: input$1.lastName,
                          onBlur: (function (param) {
                              blurLastName(form);
                            }),
                          onChange: (function ($$event) {
                              updateLastName(form, $$event.target.value);
                            })
                        })), errorNotification(lastNameResult(form)), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "form-newplayer-rating"
                        }, "Rating"), React.createElement("input", {
                          id: "form-newplayer-rating",
                          name: "rating",
                          required: true,
                          type: "number",
                          value: input$1.rating,
                          onBlur: (function (param) {
                              blurRating(form);
                            }),
                          onChange: (function ($$event) {
                              updateRating(form, $$event.target.value);
                            })
                        })), errorNotification(ratingResult(form)), React.createElement("p", undefined, React.createElement("button", {
                          disabled: submitting(form) || !valid(form)
                        }, "Add"))));
}

var NewPlayerForm = {
  make: PagePlayers$NewPlayerForm
};

function PagePlayers$PlayerList(Props) {
  var sorted = Props.sorted;
  var sortDispatch = Props.sortDispatch;
  var players = Props.players;
  var playersDispatch = Props.playersDispatch;
  var configDispatch = Props.configDispatch;
  var windowDispatchOpt = Props.windowDispatch;
  var windowDispatch = windowDispatchOpt !== undefined ? windowDispatchOpt : (function (param) {
        
      });
  var dialog = Hooks$Coronate.useBool(false);
  React.useEffect((function () {
          Curry._1(windowDispatch, {
                TAG: /* SetTitle */2,
                _0: "Players"
              });
          return (function (param) {
                    Curry._1(windowDispatch, {
                          TAG: /* SetTitle */2,
                          _0: ""
                        });
                  });
        }), [windowDispatch]);
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement("div", {
                  className: "toolbar toolbar__left"
                }, React.createElement("button", {
                      onClick: (function (param) {
                          Curry._1(dialog.setTrue, undefined);
                        })
                    }, React.createElement(ReactFeather.UserPlus, {}), " Add a new player")), React.createElement("table", {
                  style: {
                    margin: "auto"
                  }
                }, React.createElement("caption", undefined, "Player roster"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "First name",
                                  sortColumn: sortFirstName,
                                  data: sorted,
                                  dispatch: sortDispatch
                                })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "Last name",
                                  sortColumn: sortLastName,
                                  data: sorted,
                                  dispatch: sortDispatch
                                })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "Rating",
                                  sortColumn: sortRating,
                                  data: sorted,
                                  dispatch: sortDispatch
                                })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "Matches",
                                  sortColumn: sortMatchCount,
                                  data: sorted,
                                  dispatch: sortDispatch
                                })), React.createElement("th", undefined, React.createElement(VisuallyHidden, {
                                  children: "Controls"
                                })))), React.createElement("tbody", {
                      className: "content"
                    }, Belt_Array.map(sorted.table, (function (p) {
                            return React.createElement("tr", {
                                        key: Data_Id$Coronate.toString(p.id)
                                      }, React.createElement("td", {
                                            className: "table__player",
                                            colSpan: 2
                                          }, React.createElement(Router$Coronate.Link.make, {
                                                children: Data_Player$Coronate.fullName(p),
                                                to_: {
                                                  TAG: /* Player */1,
                                                  _0: p.id
                                                }
                                              })), React.createElement("td", {
                                            className: "table__number"
                                          }, p.rating), React.createElement("td", {
                                            className: "table__number"
                                          }, Data_Player$Coronate.NatInt.toInt(p.matchCount)), React.createElement("td", undefined, React.createElement("button", {
                                                className: "danger button-ghost",
                                                onClick: (function ($$event) {
                                                    var id = p.id;
                                                    $$event.preventDefault();
                                                    var playerOpt = Belt_Map.get(players, id);
                                                    if (playerOpt === undefined) {
                                                      return ;
                                                    }
                                                    var message = "Are you sure you want to delete " + Data_Player$Coronate.fullName(playerOpt) + "?";
                                                    if (window.confirm(message)) {
                                                      Curry._1(playersDispatch, {
                                                            TAG: /* Del */0,
                                                            _0: id
                                                          });
                                                      return Curry._1(configDispatch, {
                                                                  TAG: /* DelAvoidSingle */2,
                                                                  _0: id
                                                                });
                                                    }
                                                    
                                                  })
                                              }, React.createElement(ReactFeather.Trash2, {}), React.createElement(VisuallyHidden, {
                                                    children: "Delete " + Data_Player$Coronate.fullName(p) + ""
                                                  }))));
                          })))), React.createElement(Externals$Coronate.Dialog.make, {
                  isOpen: dialog.state,
                  onDismiss: (function (param) {
                      Curry._1(dialog.setFalse, undefined);
                    }),
                  ariaLabel: "New player form",
                  children: null,
                  className: ""
                }, React.createElement("button", {
                      className: "button-micro",
                      onClick: (function (param) {
                          Curry._1(dialog.setFalse, undefined);
                        })
                    }, "Close"), React.createElement(PagePlayers$NewPlayerForm, {
                      dispatch: playersDispatch
                    })));
}

function succWins(t) {
  return {
          wins: t.wins + 1 | 0,
          losses: t.losses,
          draws: t.draws
        };
}

function succLosses(t) {
  return {
          wins: t.wins,
          losses: t.losses + 1 | 0,
          draws: t.draws
        };
}

function percent(a, b) {
  var x = b !== 0 ? a / b : 0.0;
  return Numeral(x).format("%");
}

function PagePlayers$PlayerStats(Props) {
  var playerId = Props.playerId;
  var match = Db$Coronate.useAllTournaments(undefined);
  var match$1 = Belt_Map.reduce(match.items, {
        wins: 0,
        losses: 0,
        draws: 0
      }, (function (acc, _id, tournament) {
          return Belt_Array.reduce(Data_Rounds$Coronate.toArray(tournament.roundList), acc, (function (acc, round) {
                        return Belt_Array.reduce(Data_Rounds$Coronate.Round.toArray(round), acc, (function (acc, match) {
                                      var match$1 = match.result;
                                      var match$2 = Data_Id$Coronate.eq(playerId, match.blackId);
                                      var match$3 = Data_Id$Coronate.eq(playerId, match.whiteId);
                                      var exit = 0;
                                      switch (match$1) {
                                        case /* Draw */2 :
                                            if (match$3 || match$2) {
                                              return {
                                                      wins: acc.wins,
                                                      losses: acc.losses,
                                                      draws: acc.draws + 1 | 0
                                                    };
                                            } else {
                                              return acc;
                                            }
                                        case /* BlackWon */1 :
                                        case /* WhiteAborted */4 :
                                            exit = 1;
                                            break;
                                        case /* WhiteWon */0 :
                                        case /* BlackAborted */5 :
                                            exit = 2;
                                            break;
                                        case /* Aborted */3 :
                                        case /* NotSet */6 :
                                            return acc;
                                        
                                      }
                                      switch (exit) {
                                        case 1 :
                                            if (match$2) {
                                              return succWins(acc);
                                            } else if (match$3) {
                                              return succLosses(acc);
                                            } else {
                                              return acc;
                                            }
                                        case 2 :
                                            if (match$3) {
                                              return succWins(acc);
                                            } else if (match$2) {
                                              return succLosses(acc);
                                            } else {
                                              return acc;
                                            }
                                        
                                      }
                                    }));
                      }));
        }));
  var draws = match$1.draws;
  var losses = match$1.losses;
  var wins = match$1.wins;
  var total = (wins + losses | 0) + draws | 0;
  return React.createElement("div", undefined, React.createElement("table", {
                  style: {
                    margin: "0"
                  }
                }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "Stat"), React.createElement("th", undefined, "Count"), React.createElement("th", undefined, "Ratio"))), React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("th", {
                              scope: "row"
                            }, "Won"), React.createElement("td", {
                              className: "table__number"
                            }, wins), React.createElement("td", {
                              className: "table__number"
                            }, percent(wins, total))), React.createElement("tr", undefined, React.createElement("th", {
                              scope: "row"
                            }, "Lost"), React.createElement("td", {
                              className: "table__number"
                            }, losses), React.createElement("td", {
                              className: "table__number"
                            }, percent(losses, total))), React.createElement("tr", undefined, React.createElement("th", {
                              scope: "row"
                            }, "Drew"), React.createElement("td", {
                              className: "table__number"
                            }, draws), React.createElement("td", {
                              className: "table__number"
                            }, percent(draws, total))))), React.createElement("p", {
                  className: "caption-20"
                }, "These statistics are generated from current tournament data. Their total may differ from\r\n          the \"matches played\" number above."));
}

function PagePlayers$AvoidForm(Props) {
  var playerId = Props.playerId;
  var players = Props.players;
  var config = Props.config;
  var configDispatch = Props.configDispatch;
  var avoidMap = Curry._1(Data_Id$Coronate.Pair.$$Set.toMap, config.avoidPairs);
  var singAvoidList = Belt_Map.getWithDefault(avoidMap, playerId, Belt_Set.make(Data_Id$Coronate.id));
  var unavoided = Belt_SortArray.stableSortBy(Belt_Array.map(Belt_Array.keep(Belt_Map.keysToArray(players), (function (id) {
                  if (Belt_Set.has(singAvoidList, id)) {
                    return false;
                  } else {
                    return !Data_Id$Coronate.eq(id, playerId);
                  }
                })), (function (param) {
              return Data_Player$Coronate.getMaybe(players, param);
            })), Data_Player$Coronate.compareName);
  var match = React.useState(function () {
        
      });
  var setSelectedAvoider = match[1];
  var selectedAvoider = match[0];
  var selectedAvoider$1 = selectedAvoider !== undefined ? selectedAvoider : Belt_Array.get(unavoided, 0);
  var avoidAdd = function ($$event) {
    $$event.preventDefault();
    if (selectedAvoider$1 === undefined) {
      return ;
    }
    var pair = Data_Id$Coronate.Pair.make(playerId, selectedAvoider$1.id);
    if (pair !== undefined) {
      Curry._1(configDispatch, {
            TAG: /* AddAvoidPair */0,
            _0: Caml_option.valFromOption(pair)
          });
      return Curry._1(setSelectedAvoider, (function (param) {
                    
                  }));
    }
    
  };
  var handleAvoidChange = function ($$event) {
    var id = $$event.currentTarget.value;
    Curry._1(setSelectedAvoider, (function (param) {
            return Belt_Map.get(players, id);
          }));
  };
  var handleAvoidBlur = function ($$event) {
    var id = $$event.currentTarget.value;
    Curry._1(setSelectedAvoider, (function (param) {
            return Belt_Map.get(players, id);
          }));
  };
  return React.createElement(React.Fragment, undefined, Belt_Set.isEmpty(singAvoidList) ? React.createElement("p", undefined, "None") : React.createElement("ul", undefined, Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.map(Belt_Set.toArray(singAvoidList), (function (param) {
                                  return Data_Player$Coronate.getMaybe(players, param);
                                })), Data_Player$Coronate.compareName), (function (p) {
                          var fullName = Data_Player$Coronate.fullName(p);
                          return React.createElement("li", {
                                      key: Data_Id$Coronate.toString(p.id)
                                    }, fullName, React.createElement("button", {
                                          "aria-label": "Remove " + fullName + " from avoid list.",
                                          className: "danger button-ghost",
                                          title: "Remove " + fullName + " from avoid list.",
                                          onClick: (function (param) {
                                              var pair = Data_Id$Coronate.Pair.make(playerId, p.id);
                                              if (pair !== undefined) {
                                                return Curry._1(configDispatch, {
                                                            TAG: /* DelAvoidPair */1,
                                                            _0: Caml_option.valFromOption(pair)
                                                          });
                                              }
                                              
                                            })
                                        }, React.createElement(ReactFeather.Trash2, {})));
                        }))), React.createElement("form", {
                  onSubmit: avoidAdd
                }, React.createElement("label", {
                      htmlFor: "avoid-select"
                    }, "Select a new player to avoid."), selectedAvoider$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("select", {
                            id: "avoid-select",
                            value: Data_Id$Coronate.toString(selectedAvoider$1.id),
                            onBlur: handleAvoidBlur,
                            onChange: handleAvoidChange
                          }, Belt_Array.map(unavoided, (function (p) {
                                  var id = Data_Id$Coronate.toString(p.id);
                                  return React.createElement("option", {
                                              key: id,
                                              value: id
                                            }, Data_Player$Coronate.fullName(p));
                                }))), " ", React.createElement("input", {
                            className: "button-micro",
                            type: "submit",
                            value: "Add"
                          })) : "No players are available to avoid."));
}

function PagePlayers$Profile(Props) {
  var player = Props.player;
  var players = Props.players;
  var playersDispatch = Props.playersDispatch;
  var config = Props.config;
  var configDispatch = Props.configDispatch;
  var windowDispatchOpt = Props.windowDispatch;
  var windowDispatch = windowDispatchOpt !== undefined ? windowDispatchOpt : (function (param) {
        
      });
  var type_ = player.type_;
  var rating = player.rating;
  var initialMatchCount = player.matchCount;
  var playerId = player.id;
  var form = useForm({
        firstName: player.firstName,
        lastName: player.lastName,
        rating: String(rating),
        matchCount: Data_Player$Coronate.NatInt.toString(initialMatchCount)
      });
  var input$1 = input(form);
  var playerName = input$1.firstName + " " + input$1.lastName;
  React.useEffect((function () {
          Curry._1(windowDispatch, {
                TAG: /* SetTitle */2,
                _0: "Profile for " + playerName
              });
          return (function (param) {
                    Curry._1(windowDispatch, {
                          TAG: /* SetTitle */2,
                          _0: ""
                        });
                  });
        }), [
        windowDispatch,
        playerName
      ]);
  var match = matchCountResult(form);
  var tmp;
  tmp = match !== undefined && match.TAG === /* Ok */0 ? Data_Ratings$Coronate.EloRank.getKFactor(match._0, rating) : Data_Ratings$Coronate.EloRank.getKFactor(initialMatchCount, rating);
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement(Router$Coronate.Link.make, {
                  children: null,
                  to_: /* PlayerList */2,
                  onClick: (function ($$event) {
                      if (dirty(form) && !window.confirm("Discard changes?")) {
                        $$event.preventDefault();
                        return ;
                      }
                      
                    })
                }, React.createElement(ReactFeather.ChevronLeft, {}), " Back"), React.createElement("h2", undefined, "Profile for " + playerName), React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      submit(form, (function (param) {
                              Curry._1(playersDispatch, {
                                    TAG: /* Set */1,
                                    _0: playerId,
                                    _1: {
                                      firstName: param.firstName,
                                      id: playerId,
                                      lastName: param.lastName,
                                      matchCount: param.matchCount,
                                      rating: param.rating,
                                      type_: type_
                                    }
                                  });
                            }));
                    })
                }, React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "firstName"
                        }, "First name"), React.createElement("input", {
                          name: "firstName",
                          type: "text",
                          value: input$1.firstName,
                          onBlur: (function (param) {
                              blurFirstName(form);
                            }),
                          onChange: (function ($$event) {
                              updateFirstName(form, $$event.target.value);
                            })
                        })), errorNotification(firstNameResult(form)), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "lastName"
                        }, "Last name"), React.createElement("input", {
                          name: "lastName",
                          type: "text",
                          value: input$1.lastName,
                          onBlur: (function (param) {
                              blurLastName(form);
                            }),
                          onChange: (function ($$event) {
                              updateLastName(form, $$event.target.value);
                            })
                        })), errorNotification(lastNameResult(form)), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "matchCount"
                        }, "Matches played"), React.createElement("input", {
                          name: "matchCount",
                          type: "number",
                          value: input$1.matchCount,
                          onBlur: (function (param) {
                              Curry._1(form.dispatch, /* BlurMatchCountField */0);
                            }),
                          onChange: (function ($$event) {
                              var nextValue = $$event.target.value;
                              Curry._1(form.dispatch, {
                                    TAG: /* UpdateMatchCountField */0,
                                    _0: nextValue
                                  });
                            })
                        })), errorNotification(matchCountResult(form)), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "rating"
                        }, "Rating"), React.createElement("input", {
                          name: "rating",
                          type: "number",
                          value: input$1.rating,
                          onBlur: (function (param) {
                              blurRating(form);
                            }),
                          onChange: (function ($$event) {
                              updateRating(form, $$event.target.value);
                            })
                        })), errorNotification(ratingResult(form)), React.createElement("p", undefined, React.createElement("button", {
                          disabled: submitting(form) || !valid(form)
                        }, dirty(form) ? "Save" : "Saved"))), React.createElement("h3", undefined, "Players to avoid"), React.createElement(PagePlayers$AvoidForm, {
                  playerId: playerId,
                  players: players,
                  config: config,
                  configDispatch: configDispatch
                }), React.createElement("hr", undefined), React.createElement("h3", undefined, "Statistics"), React.createElement(PagePlayers$PlayerStats, {
                  playerId: playerId
                }), React.createElement("hr", undefined), React.createElement("details", undefined, React.createElement("summary", undefined, "Additional information"), React.createElement("dl", undefined, React.createElement("dt", undefined, "K-factor"), React.createElement("dd", {
                          className: "monospace"
                        }, tmp)), React.createElement("p", {
                      className: "caption-20"
                    }, "K-factor is 40 for players who have played fewer than 30 matches, 20 for players with\r\n            a rating below 2100, and 10 for players with a rating above 2100.")));
}

var Profile = {
  make: PagePlayers$Profile
};

function PagePlayers(Props) {
  var id = Props.id;
  var windowDispatch = Props.windowDispatch;
  var match = Db$Coronate.useAllPlayers(undefined);
  var playersDispatch = match.dispatch;
  var players = match.items;
  var match$1 = Hooks$Coronate.useSortedTable(Belt_Map.valuesToArray(players), sortFirstName, false);
  var sortDispatch = match$1[1];
  React.useEffect((function () {
          Curry._1(sortDispatch, {
                TAG: /* SetTable */2,
                _0: Belt_Map.valuesToArray(players)
              });
        }), [
        players,
        sortDispatch
      ]);
  var match$2 = Db$Coronate.useConfig(undefined);
  var configDispatch = match$2[1];
  var tmp;
  if (id !== undefined) {
    var player = Belt_Map.get(players, Caml_option.valFromOption(id));
    tmp = player !== undefined ? React.createElement(PagePlayers$Profile, {
            player: player,
            players: players,
            playersDispatch: playersDispatch,
            config: match$2[0],
            configDispatch: configDispatch,
            windowDispatch: windowDispatch
          }) : React.createElement("div", undefined, "Loading...");
  } else {
    tmp = React.createElement(PagePlayers$PlayerList, {
          sorted: match$1[0],
          sortDispatch: sortDispatch,
          players: players,
          playersDispatch: playersDispatch,
          configDispatch: configDispatch,
          windowDispatch: windowDispatch
        });
  }
  return React.createElement(Window$Coronate.Body.make, {
              children: tmp,
              windowDispatch: windowDispatch
            });
}

var make = PagePlayers;

export {
  NewPlayerForm ,
  Profile ,
  make ,
}
/* react Not a pure module */
